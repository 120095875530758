<template>
  <div
    data-cy="view-conversations-message-area-empty"
    class="message-placeholder"
  >
    <ChatConversationMessage
      :author="author"
      :message="message"
      :show-empty-avatar="showEmptyAvatar"
    />
  </div>
</template>

<script>
import ChatConversationMessage from './chat-conversation-message.vue';

export default {
  name: 'ChatConversationMessageAreaEmpty',
  components: {
    ChatConversationMessage,
  },
  data() {
    return {
      author: { name: this.$t('shared.conversations.a_little_empty') },
      message: {
        text: this.$t('shared.conversations.start_conversation_below'),
      },
      showEmptyAvatar: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.message-placeholder {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 24px;
}
</style>
