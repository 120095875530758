import api from './api';
import echo from './echo';
import Bugsnag from '@bugsnag/js';

export default function apiplugin(store) {
  if (store.state.auth.access_token) {
    api.defaults.headers.common.authorization = `Bearer ${store.state.auth.access_token}`;
    echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${store.state.auth.access_token}`;
  }

  store.subscribe(({ payload, type } = {}) => {
    if (type === 'users/set_me') {
      setBugsnagUser(payload);
      initializeHelpscoutBeacon(payload);
      setApiDefaultParams(payload);
    }

    if (type === 'auth/updateToken') {
      api.defaults.headers.common.authorization = `Bearer ${payload}`;
      echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${payload}`;
    }

    if (type === 'auth/clearToken') {
      delete api.defaults.headers.common.authorization;
      // TODO Also clear echo token
    }
  });
}

function setBugsnagUser({ id, name, email }) {
  Bugsnag.user = {
    id,
    name,
    email,
  };
}

function initializeHelpscoutBeacon(payload) {
  if (!globalThis.Beacon) {
    globalThis.Beacon('destroy');
    return;
  }

  if (!(payload.id && payload.email)) return;

  // we got a user, show Beacon and send user data
  globalThis.Beacon('init', '03c7e398-7eb1-434c-8832-76e350e5e9b3');

  if (!payload.super_user) return;

  globalThis.Beacon('identify', {
    id: payload.id,
    name: payload.name,
    email: payload.email,
    type: 'customer',
    company: payload.customer?.name,
    created_at: payload.created_at?.split('T')[0],
    avatar:
      'https://imagedelivery.net/veAPhTcQT2sdfpabQ16DDg/2ea9223d-841c-4bed-d61d-f885d8694600/public',
  });
}

function setApiDefaultParams(payload) {
  api.defaults.params =
    typeof api.defaults.params === 'undefined' ? {} : api.defaults.params;
  api.defaults.params.lang = payload.lang;
}
