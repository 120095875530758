import store from '@/store';
const routes = [
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-profile.vue'
      ),
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: '/settings/general',
    beforeEnter: (_, _2, next) => {
      redirectIfNotOwner(next);
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single.vue'
      ),
    children: [
      {
        path: 'general',
        name: 'General',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-general.vue'
          ),
      },
      {
        path: 'team',
        name: 'Team',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-team.vue'
          ),
      },
      {
        path: 'employees',
        name: 'employees',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-employees.vue'
          ),
      },
    ],
  },
  {
    path: '/employees/:id',
    name: 'employee-edit',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-edit-employees.vue'
      ),
    beforeEnter: (_, _2, next) => {
      redirectIfNotOwner(next);
    },
  },
  {
    path: '/team/:id',
    name: 'Edit user',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '@/views/settings/pages/settings-single-edit-user.vue'
      ),
    beforeEnter: (_, _2, next) => {
      redirectIfNotOwner(next);
    },
  },
];

async function redirectIfNotOwner(next) {
  // somehow the initial loading isn't working anymore and we
  // get null here, causing the /settings/* routes to redirect to /profile
  // this fixes it but is pretty dirty
  if (!store.getters['users/me']) {
    await store.dispatch('users/get_me');
  }

  if (store.getters['users/me']?.customer?.rights?.owner) {
    next();
  } else {
    next('/profile');
  }
}
export default routes.map((route) => ({ ...route, meta: {} }));
