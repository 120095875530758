const ratings = [
  {
    path: '/ratings',
    name: 'Ratings List',
    component: () =>
      import(
        /* webpackChunkName: "partners" */ '@/views/ratings/pages/ratings-list.vue'
      ),
  },
];

export default ratings.map((route) => ({ ...route, meta: {} }));
