<template>
  <div
    v-if="showing"
    class="remote-refresh-container"
  >
    <div class="remote-refresh-banner">
      <div class="text-container">
        <div class="title-container">
          <Icon
            name="info"
            class="icon"
            :size="24"
          />
          <span class="title">{{ $t('shared.update_available') }}</span>
        </div>
        <span class="description">{{ $t('shared.continue_without_update_explainer') }}</span>
      </div>

      <ButtonV2
        icon-left="reload"
        :icon-size="16"
        small
        class="reload-button"
        color="orange"
        @click="reload"
      >
        {{ $t('shared.update_now') }}
      </ButtonV2>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';
import ButtonV2 from './ButtonV2.vue';

export default {
  name: 'RemoteRefreshBanner',
  components: {
    Icon,
    ButtonV2,
  },
  props: {
    sidebarOpen: {
      type: Boolean,
      default: null,
    },
    echoInstance: {
      type: Object,
      required: true,
    },
    echoChannel: {
      type: String,
      required: true,
    },
  },
  emits: ['showing'],
  data() {
    return {
      showing: false,
    };
  },
  mounted() {
    this.echoInstance.channel(this.echoChannel).listen('refresh', (e) => {
      if (e.type === 'soft') {
        this.showing = true;
        this.$emit('showing');
      }
      if (e.type === 'force') {
        this.reload();
      }
    });
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.remote-refresh-container {
  display: flex;
  height: 56px;
  width: 100%;
  animation: slideDown .7s linear;
}

.remote-refresh-banner {
  background-color: $color-brand-shade-600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  position: fixed;
  width: inherit;
  z-index: 1;
}

.icon {
  color: #fff;
  margin-right: 4px;
}

.text-container {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
}

.title {
  @include font-body-14-semi-bold;
  color: #fff;
  margin-right: 4px;
}

.description {
  @include font-caption-medium;
  color: #fff;
  margin-right: 16px;
  margin-bottom: -2px;
}

.reload-button :deep(.button) {
  color: #000 !important;
}

.sidebar-open {
  margin-left: 208px;
  width: calc(100% - 208px);
}

@include medium-down {

  .description {
    display: none;
  }

  .remote-refresh-banner {
    justify-content: space-between;
  }

  .text-container {
    margin-left: 16px;
  }

  .reload-button {
    margin-right: 16px;
  }
}

@include small-down {

  .sidebar-open {
    /*
    the sidebar classes are only applied in the customer and partner app,
    where the mobile top navigation also exists
    the padding-top is only needed in these apps to compensate for the mobile navigation bar
    */
    .remote-refresh-banner {
      padding-top: 50px;
    }
  }

  .remote-refresh-banner {
    box-sizing: content-box;
  }
}

@keyframes slideDown {

  from {
    margin-top: -56px;
  }

  to {
    margin-top: 0px;
  }
}
</style>
