class QueryAbstract {
  constructor() {
    this._limitsResults = true;
    this._tags = new Set();
    this.reset();
  }

  doesNotLimitResults() {
    this._limitsResults = false;

    return this;
  }

  isLimitingResults() {
    return this._limitsResults;
  }

  isActive() {
    return Object.values(this.serialize()).some(value => this._hasValidValue(value));
  }

  _hasValidValue(value) {
    if (typeof value === 'boolean') return true;
    if (value === null) return false;
    if (typeof value === 'number') return !isNaN(value);
    if (Array.isArray(value) && value.length === 0) return false;
    if (typeof value === 'object' && !Array.isArray(value) && !Object.keys(value).length) return false;

    return value;
  }

  serialize() {
    throw new Error('Implement serialize() when using QueryAbstract');
  }

  unserialize() {
    throw new Error('Implement unserialize() when using QueryAbstract');
  }

  reset() {
    throw new Error('Implement reset() when using QueryAbstract');
  }

  _getWrongTypeError(value, expected = 'string') {
    return new Error(`Wrong type given. Expected ${expected}, got ${typeof value}.`);
  }

  addTag(tag) {
    this._tags.add(tag);

    return this;
  }

  hasTag(tag) {
    return this._tags.has(tag);
  }
}

export { QueryAbstract }