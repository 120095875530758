import moment from 'moment-timezone';
import BaseModel from '@/store/models/BaseModel';
import Lunch from './Lunch';

const HIDDEN = [
  'user_id',
  'customer_id',
  'partner_id',
  'settings',
  'pause_at',
  'pause_until',
  'cancelled_at',
  'expires',
  'start_date',
  'end_date',
  'approved_at',
  'created_at',
  'updated_at',
  'deleted_at',
  'module',
];

export default class Contract extends BaseModel {
  #getters;

  static STATUS = {
    ACTIVE: 'active',
    ENDED: 'ended',
    ACCEPTED: 'accepted',
    CHANGED: 'changed',
    DISPUTED: 'disputed',
    OPEN: 'open',
    DECLINED: 'declined',
    DRAFT: 'draft',
  };

  constructor(props, getters) {
    super(props, HIDDEN);

    this.getters = getters;
    this.modelType = 'Contract';
  }

  get settings() {
    return super.get('settings', { settings: [] }).settings;
  }

  get minPax() {
    return super.get('settings', { settings: [] }).min_pax;
  }

  get scheduledOrders() {
    return super.get('settings', { orders: [] }).orders;
  }

  get createdAt() {
    const date = super.get('created_at');
    return date ? moment.utc(date).local() : null;
  }

  get updatedAt() {
    const date = super.get('updated_at');
    return date ? moment.utc(date).local() : null;
  }

  get startDate() {
    const date = super.get('start_date');
    return date ? moment.utc(date).local() : null;
  }

  get endDate() {
    const date = super.get('end_date');
    return date ? moment.utc(date).local() : null;
  }

  get orderLines() {
    return (
      this.settings.order_lines || [
        { amount: 0, line: '', single: 0, unit: null },
      ]
    );
  }

  get note() {
    return this.settings.note || null;
  }

  get module() {
    if (super.get('module') && super.get('module').module_type === 'lunch') {
      const module = {
        ...super.get('module'),
        note: this.settings.note,
        message: this.settings.message,
        uploaded_files: this.settings.files,
      };
      return new Lunch(module);
    }
    return null;
  }
}
