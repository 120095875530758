<script>
import { h } from 'vue';

export default {
  compatConfig: {
    MODE: 3,
  },
  name: 'OgIntercom',
  props: {
    userId: {
      type: String,
      required: true,
    },
    intercomAppId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await import('./intercom');

    this.shutdownIntercom();

    window.Intercom('boot', {
      app_id: this.intercomAppId,
      user_id: this.userId,
    });
  },
  unmounted() {
    this.shutdownIntercom();
  },
  methods: {
    shutdownIntercom() {
      window.Intercom('shutdown');
    },
  },
  render() {
    return h('template');
  },
};
</script>
