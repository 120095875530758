import {QueryAbstract} from './query-abstract';

class QueryCustomers extends QueryAbstract {
  reset() {
    this._customers = [];
  }

  serialize() {
    return {
      customers: this._customers,
    }
  }

  unserialize(filter) {
    const { customers } = filter;

    if (typeof customers === 'string') this._customers = [customers];
    if (Array.isArray(customers)) this._customers = customers;
  }

  set customers(value) {
    this._customers = value;
  }

  get customers() {
    return this._customers;
  }
}

export { QueryCustomers }