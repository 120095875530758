import api from '../plugins/api';
import Module from '../models/Module';

export default {
  namespaced: true,
  state: {
    all: [],
    meta: {},
    single: {},
    singleLoading: {},
    settings: {},
    choices: {},
    choicesLoading: {},
    employees: {},
  },
  getters: {
    all(state) {
      return state.all;
    },
    single(state) {
      return ({ id }) => {
        return state.single[id] ? new Module(state.single[id]) : null;
      };
    },
    singleLoading(state) {
      return ({ id }) => {
        return state.singleLoading[id] || false;
      };
    },
    settings(state) {
      return ({ id }) => {
        return state.settings[id];
      };
    },
    choices_bydate(state) {
      return ({ id, from, to }) => {
        if (!state.choices[id]) return [];
        return state.choices[id].filter((choice) => {
          return choice.date >= from && choice.date <= to;
        });
      };
    },
    choicesLoading(state) {
      return ({ id }) => {
        return state.choicesLoading[id] || false;
      };
    },
    meta(state) {
      return ({ id, from, to }) => {
        if (!state.meta[id]) return {};
        return state.meta[id][`${from}:${to}`];
      };
    },
    employees(state) {
      return ({ id }) => state.employees[id] || [];
    },
  },
  mutations: {
    set_all(state, modules) {
      state.all = modules;
    },
    set_single(state, { id, data }) {
      state.single = { ...state.single, [id]: data };
    },
    set_single_loading(state, { id }) {
      state.singleLoading = { ...state.singleLoading, [id]: true };
    },
    set_single_loaded(state, { id }) {
      state.singleLoading = { ...state.singleLoading, [id]: false };
    },
    set_settings(state, { id, settings }) {
      state.settings[id] = settings;
    },
    set_choices_bydate(state, { id, choices }) {
      if (!state.choices[id]) {
        state.choices[id] = [];
      }
      choices.forEach((choice) => {
        const oldIndex = state.choices[id].findIndex(
          (c) => c.date === choice.date
        );
        if (oldIndex !== -1) {
          state.choices[id][oldIndex] = choice;
        } else {
          state.choices[id].push(choice);
        }
      });
    },
    set_choices_loading(state, { id }) {
      state.choicesLoading = { ...state.choicesLoading, [id]: true };
    },
    set_choices_loaded(state, { id }) {
      state.choicesLoading = { ...state.choicesLoading, [id]: false };
    },
    // This doesn't feel like a nice way to do this, but maybe
    // it's a fine solution as menus might be changed in the future
    set_meta(state, { id, meta, from, to }) {
      if (!state.meta[id]) {
        state.meta[id] = {};
      }
      state.meta[id][`${from}:${to}`] = meta;
    },
    set_employees(state, { id, employees }) {
      state.employees[id] = employees;
    },
  },
  actions: {
    get_all({ commit }) {
      return api.get('/modules').then(({ data }) => {
        commit('set_all', data.data);
      });
    },
    get_single({ commit }, { id }) {
      commit('set_single_loading', { id });
      return api
        .get(`/modules/${id}`, {
          params: {
            with_deleted: true,
          },
        })
        .then(({ data }) => {
          commit('set_single', { id, data: data.data });
        })
        .finally(() => {
          commit('set_single_loaded', { id });
        });
    },
    get_settings({ commit }, { id }) {
      return api.get(`/modules/${id}/settings`).then(({ data }) => {
        commit('set_settings', { id, settings: data.data });
      });
    },
    update_settings({ commit }, { id, formData }) {
      return api.put(`/modules/${id}/settings`, formData).then(({ data }) => {
        commit('set_settings', { id, settings: data.data });
      });
    },
    get_choices_bydate({ commit }, { id, from, to, detailed = true }) {
      commit('set_choices_loading', { id });

      return api
        .get(`/modules/${id}/choices`, {
          params: {
            detailed,
            start_date: from,
            end_date: to,
            with_deleted: true,
          },
        })
        .then(({ data }) => {
          commit('set_choices_bydate', { id, choices: data.data });
          commit('set_meta', { id, meta: data.meta, from, to });
        })
        .finally(() => {
          commit('set_choices_loaded', { id });
        });
    },
    update_choices(context, { id, date, payload }) {
      return api.put(`/modules/${id}/choices`, payload, {
        params: {
          date,
        },
      });
    },
    get_employees({ commit }, { id }) {
      return api.get(`/modules/${id}/employees`).then(({ data }) => {
        commit('set_employees', { id, employees: data.data });
      });
    },
    update_employee(context, { moduleId, employeeId, formData }) {
      return api.put(
        `/modules/${moduleId}/employees/${employeeId}/settings`,
        formData
      );
    },
    addEmployee(context, { moduleId, employeeId }) {
      return api.post(`/modules/${moduleId}/employees/${employeeId}`);
    },
    addEmployees({ dispatch }, { employees, moduleId }) {
      const requests = [];
      employees.forEach((employee) => {
        requests.push(
          dispatch('addEmployee', {
            employeeId: employee.id,
            moduleId,
          })
        );
      });
      return Promise.all(requests);
    },
    removeEmployee(context, { employeeId, moduleId }) {
      return api.delete(`/modules/${moduleId}/employees/${employeeId}`);
    },
  },
};
