import BaseModel from '@/store/models/BaseModel';
import Offer from '@/store/models/Offer';
import Contract from '@/store/models/Contract';

const STATUSMAP = {
  accepted: 'active',
  changed: 'active',
  offers: 'offers',
  ended: 'closed',
  disputed: 'disputed',
};

const hidden = [
  'service_id',
  'user_id',
  'contracts',
  'offers',
  'customer_id',
  'contact_id',
  'location_id',
  'created_at',
  'updated_at',
  'deleted_at',
];

export default class Task extends BaseModel {
  #getters;

  constructor(props, getters) {
    super(props, hidden);
    this.getters = getters;
  }

  get activeOffer() {
    const activeOffer = this.offers.find((o) =>
      ['ended', 'accepted', 'changed', 'disputed'].includes(o.status)
    );

    return activeOffer ? new Offer(activeOffer) : null;
  }

  get activeContract() {
    // @TODO: One could argue about whether this logic belongs here or in the Contract :-*
    const activeContract = this.contracts.find((c) =>
      [
        Contract.STATUS.ACTIVE,
        Contract.STATUS.ENDED,
        Contract.STATUS.ACCEPTED,
        Contract.STATUS.CHANGED,
        Contract.STATUS.DISPUTED,
      ].includes(c.status)
    );

    return activeContract || null;
  }

  get contract() {
    const contracts = this.getters['contracts/belongingToTask'](
      super.get('id')
    );

    return contracts.length ? contracts[0] : null;
  }

  get contracts() {
    return super
      .get('contracts', [])
      .map((contract) => new Contract(contract, this.getters));
  }

  get offers() {
    return super.get('offers', []).map((o) => new Offer(o));
  }

  get combined() {
    const combined = [...this.contracts];
    const partnerIds = combined.map((c) => c.partner.id);
    // Add any offer to combined, that doesn't already have a contract
    this.offers.forEach((offer) => {
      if (!partnerIds.includes(offer.partner.id)) {
        combined.push(offer);
      }
    });
    return combined;
  }

  get hasBeenAccepted() {
    return !!this.activeContract;
  }

  // eslint-disable-next-line class-methods-use-this
  set status(value) {
    // ignore the backend status here for now - it's now shipped but the
    // frontend relies on the computed status so changing it is likely
    // to break some places
  }

  get status() {
    if (this.contracts.length === 0) {
      return 'waiting';
    }
    if (this.activeContract) {
      return STATUSMAP[this.activeContract.status];
    }
    return 'offers';
  }

  get type() {
    if (this.activeContract) {
      return this.activeContract.recurring ? 'recurring' : 'oneoff';
    }
    return null;
  }

  get allDeclined() {
    if (this.offers.length !== 0) {
      if (
        this.status === 'offers' &&
        !this.offers.find((o) => o.status === 'open')
      ) {
        return true;
      }
    }
    return false;
  }

  get formattedCreatedAtDate() {
    return new Date(super.get('created_at')).toLocaleString(
      this.getters['settings/locale'],
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }
    );
  }
}
