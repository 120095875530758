class RequestManager {
  constructor(apiInterface = {}) {
    if (typeof apiInterface.get !== 'function') throw new Error('Provide an object with a get method');

    this.api = apiInterface;
    this.loading = false;
    this.errored = false;
  }

  async request(endpoint, params = []) {
    this.loading = true;
    this.errored = false;

    let response;
    try {
      response = await this.api.get(endpoint, {
        params,
      });
      this.loading = false;
    } catch(e) {
      this.errored = true;
      this.loading = false;
      // TODO: Handle properly
      throw e;
    }

    return {
      data: response.data.data,
      meta: response.data.meta
    };
  }
}

export { RequestManager }