/*
  This implementation assumes that the formatter that adheres to
  formatterInterface is locale aware and can therefore produce
  different outputs based on its internal state.
 */
function format(formatterInterface, formatString) {
  if (typeof formatterInterface.format !== 'function')
    throw Error('formatterInterface needs a format function.');

  return formatterInterface.format(formatString);
}

function time(formatterInterface) {
  return format(formatterInterface, 'LT');
}

function numeric(formatterInterface) {
  return format(formatterInterface, 'L');
}

function numericDatetime(formatterInterface) {
  return format(formatterInterface, 'L LTS');
}

function medium(formatterInterface) {
  return format(formatterInterface, 'LLL');
}

function long(formatterInterface) {
  return format(formatterInterface, 'LLLL');
}

// The Javascript `toISODate` function changes the date to a timezone less
// UTC date. This can also alter the day, month and year, depending on the
// timezone offset. When we just need todays date as an ISO string in the
// current users timezone, we can use this function.
function toShortISOStringNoUTC(date) {
  const day = zeroPad(date.getDate());
  const month = zeroPad(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

function zeroPad(number) {
  const string = String(number);

  return string.length < 2 ? `0${string}` : string;
}

export { time, numeric, numericDatetime, medium, long, toShortISOStringNoUTC };
