<template>
  <div
    ref="wrapper"
    class="popover-wrapper"
  >
    <div
      class="popover-trigger"
      @click="toggleShowing"
    >
      <slot name="trigger" />
    </div>
    <div
      v-if="showing"
      ref="popover"
      class="popover-content-outer"
      :class="{
        'anchor-right': anchorRight,
        'anchor-top': anchorTop,
        'no-padding': noPadding,
      }"
      :style="`
      margin-left: ${offsetX};
      margin-top: ${anchorTop ? 'initial' : offsetY};
      margin-bottom: ${anchorTop ? offsetY : 'initial'};
      width: ${contentWidth};
      max-height:${contentMaxHeight};
      ${contentMaxHeight !== 'auto' ? 'overflow-y:auto;' : ''}
      `"
      @click="onHideOnClickInside"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popover',
  props: {
    disabled: {
      type: Boolean,
    },
    noPadding: {
      type: Boolean,
    },
    anchorRight: {
      type: Boolean,
    },
    anchorTop: {
      type: Boolean,
    },
    offsetX: {
      type: String,
      default: '0',
    },
    offsetY: {
      type: String,
      default: '0',
    },
    hideOnClickInside: {
      type: Boolean,
    },
    contentWidth: {
      type: String,
      default: 'auto',
    },
    contentMaxHeight: {
      type: String,
      default: 'auto',
    },
    preventHideOnClickOutside: {
      type: Boolean,
    },
  },
  emits: ['show', 'hide', 'interface'],
  data() {
    return {
      showing: false,
      ignoreNextClickOutside: false,
    };
  },
  watch: {
    showing: {
      handler(newVal) {
        this.$nextTick(() => {
          this.$emit(newVal ? 'show' : 'hide');
          if (newVal) this.correctContentPosition();
        });
      },
    },
  },
  created() {
    document.addEventListener('click', this.hideOnClickOutside);
    this.exposeInterface();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.hideOnClickOutside);
  },
  methods: {
    onHideOnClickInside() {
      if (this.hideOnClickInside) {
        this.hide();
      }
    },
    toggleShowing() {
      if (this.disabled) return;
      this.showing = !this.showing;
    },
    show() {
      this.ignoreNextClickOutside = true;
      this.showing = true;
      setTimeout(() => {
        this.ignoreNextClickOutside = false;
      }, 50);
    },
    hide() {
      this.showing = false;
    },
    hideOnClickOutside(e) {
      if (this.preventHideOnClickOutside) return;

      if (!this.$refs.wrapper.contains(e.target)) {
        if (this.ignoreNextClickOutside) {
          this.ignoreNextClickOutside = false;
        } else {
          this.showing = false;
        }
      }
    },
    correctContentPosition() {
      const position = this.$refs.popover.getBoundingClientRect();
      if (!this.anchorRight) {
        if (position.right > window.innerWidth) {
          this.$refs.popover.style.left = `-${position.right - window.innerWidth}px`;
        }
      } else if (position.left < 0) {
        this.$refs.popover.style.right = `${position.left}px`;
      }
      if (!this.anchorTop) {
        if (position.bottom > window.innerHeight) {
          this.$refs.popover.style.top = `${
            this.$refs.wrapper.clientHeight - (position.bottom - window.innerHeight)
          }px`;
        }
      } else if (position.top < 0) {
        this.$refs.popover.style.bottom = `${this.$refs.wrapper.clientHeight + position.top}px`;
      }
    },
    exposeInterface() {
      this.$emit('interface', {
        show: () => this.show(),
        hide: () => this.hide(),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.popover-wrapper {
  position: relative;
  display: inline-block;
}

.popover-trigger {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > :first-child {
    height: 100%;
  }
}

.popover-content-outer {
  border: 1px solid $color-grey-tinted-200;
  border-radius: 12px;
  padding: 12px;
  position: absolute;
  background-color: #fff;
  min-width: 0;
  z-index: 100000;
  overflow: visible;
  box-shadow: 0px 7px 24px rgba(5, 5, 31, .04);
}

.no-padding {
  padding: 0;
}

.anchor-right {
  right: 0;
}

.anchor-top {
  bottom: 100%;
}
</style>
