import Service from '@/store/models/Service';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    services: [],
    servicesSingle: [],
    searchResults: [],
  },
  getters: {
    list(state) {
      return state.services.map((service) => new Service(service));
    },
    single: (state) => ({ id }) => {
      const service = state.servicesSingle.find((c) => c.id === id);

      return service ? new Service(service) : null;
    },
    // this is reusing the logic of "single"
    // it has to exist to be used by the internal logic of the Async component
    public_single: (state, getters) => (params) => {
      return getters.single(params);
    },
  },
  mutations: {
    set_list(state, services) {
      state.services = services;
    },
    set_single(state, service) {
      const oldItem = state.servicesSingle.find((t) => t.id === service.id);
      if (oldItem) {
        const index = state.servicesSingle.findIndex(
          (p) => p.id === service.id
        );
        state.servicesSingle[index] = { ...oldItem, ...service };
      } else {
        state.servicesSingle.push(service);
      }
    },
    setResults(state, results) {
      state.searchResults = results;
    },
  },
  actions: {
    get_list({ commit }) {
      return api
        .get('/services', {
          params: {
            with: [],
          },
        })
        .then(({ data }) => {
          commit('set_list', data.data);
        });
    },
    get_single({ commit }, { id }) {
      return api
        .get(`/services/${id}`, {
          params: {
            with: ['questions', 'partners'],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);
        });
    },
    get_public_single({ commit }, { id }) {
      return api.get(`/public/services/${id}`).then(({ data }) => {
        commit('set_single', data.data);
      });
    },
    search({ commit }, q) {
      return api
        .get('/services', {
          params: {
            search: q,
          },
        })
        .then(({ data }) => {
          commit('setResults', data.data);
        });
    },
    request_help(_, id) {
      return api.request({
        url: `/services/${id}/help`,
        method: 'post',
      });
    },
  },
};
