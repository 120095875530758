<template>
  <div
    class="conversation-handled-banner"
    data-cy="conversation-handled-banner"
  >
    <Icon
      name="checkmark_bubble_bold"
      class="conversation-handled-banner__icon"
      :size="18"
      color="#08856A"
    />
    <p
      class="handled-by-text"
      :title="$t('shared.conversations.handled_by_name', { name: conversation.handled.user.name })"
    >
      {{ $t('shared.conversations.handled_by_name', { name: conversation.handled.user.name }) }}
    </p>
    <p class="handled-by-time">
      {{ handledTime }}
    </p>
    <InfoTag
      class="handled-by-infotag"
      small
      icon-left="eye-crossed"
    >
      {{ $t('shared.conversations.not_visible_for_customer') }}
    </InfoTag>
  </div>
</template>

<script>

import { format } from 'date-fns';
import Icon from '../Icon.vue';
import InfoTag from '../InfoTag.vue';

export default {
  name: 'ConversationHandledBanner',
  components: { Icon, InfoTag },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    handledTime() {
      return format(new Date(this.conversation.handled.handled_at), 'HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

.conversation-handled-banner {
  background-color: $color-semantic-success-lightest;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
}

.handled-by-text {
  margin-left: 16px;

  @include overflow-ellipsis;

  @include font-body-14-medium;
  color: $color-semantic-success-dark;
}

.handled-by-time {
  margin-left: 4px;

  @include font-caption-normal;
  color: $color-grey-tinted-500;
}

.handled-by-infotag {
  margin-left: 12px;
}

.conversation-handled-banner__icon {
  flex-shrink: 0;
}
</style>
