import BaseModel from '@/store/models/BaseModel';

const hidden = ['services'];

export default class Category extends BaseModel {
  constructor(props) {
    super(props, hidden);
  }

  get services() {
    const services = [...super.get('services', [])];
    return services.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
