import { h } from 'vue';
export default {
  compatConfig: {
    MODE: 3,
  },
  created() {
    this.$store.dispatch('wootric/setup');
  },
  render() {
    h('div');
  },
};
