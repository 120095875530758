import moment from 'moment-timezone';
import Order from '../../models/Order';

/*
  I have been a bit under pressure fixing this getter and therefore decided
  to not go the long way of mocking all the stores dependencies for being able
  to run it in Jest, but just exclude the functionality to a file and import
  it. We can continue going that way (less bootstrapping in the test, but more
  files and indirections) or we can just require the whole store module, mock
  all the dependencies and run the tests there.
 */
const getOrders = (state, _1, _2, getters) => ({
  id,
  startDate: contractStartDate,
  endDate: contractEndDate,
}) => {
  // Ignore start and end time, just focus on comparing the days
  // @SEE https://officeguru.atlassian.net/browse/OG-1768
  return state.orders
    .filter((o) => {
      const orderStartDate = moment(o.date);

      return (
        o.contract_id === id &&
        orderStartDate.isBetween(
          contractStartDate,
          contractEndDate,
          'day', // granularity
          '[]' // include start and end
        )
      );
    })
    .map((o) => new Order(o, getters))
    .sort((a, b) => {
      if (a.date.isBefore(b.date)) return -1;
      if (b.date.isBefore(a.date)) return 1;
      return 0;
    });
};

export { getOrders };
