import Partner from '@/store/models/Partner';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    partners: [],
  },
  getters: {
    single: (state, _1, _2, getters) => ({ id }) => {
      const partner = state.partners.find((p) => p.id === id);

      return partner ? new Partner(partner, getters) : null;
    },
  },
  mutations: {
    set_single(state, partner) {
      const oldItem = state.partners.find((p) => p.id === partner.id);
      if (oldItem) {
        const index = state.partners.findIndex((p) => p.id === partner.id);
        state.partners[index] = { ...oldItem, ...partner };
      } else {
        state.partners.push(partner);
      }
    },
  },
  actions: {
    get_single_external(_, { id }) {
      return api.get(`customer/partners/external/${id}`);
    },
    create_external(_, { data }) {
      return api.post(`customer/partners/external`, data);
    },
    update_external(_, { id, data }) {
      return api.put(`customer/partners/external/${id}`, data);
    },
    get_single({ commit }, { id }) {
      return api
        .get(`/partners/${id}`, {
          params: {
            with: ['services', 'contracts.task.service', 'offers.task.service'],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);

          // eslint-disable-next-line no-unused-expressions
          data.data.services?.forEach((service) => {
            commit('services/set_single', service, { root: true });
          });

          // eslint-disable-next-line no-unused-expressions
          data.data.offers?.forEach((offer) => {
            commit('offers/set_single', offer, { root: true });
          });

          // eslint-disable-next-line no-unused-expressions
          data.data.contracts?.forEach((contract) => {
            commit('contracts/set_single', contract, { root: true });
          });
        });
    },
  },
};
