<template>
  <Popover
    :content-width="contentWidth"
    :content-max-height="contentMaxHeight"
    :anchor-right="anchorRight"
    :anchor-top="anchorTop"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :hide-on-click-inside="hideOnClickInside"
    :no-padding="noPadding"
    :disabled="disabled"
    class="popover"
    tabindex="0"
    :class="{ expanded: isExpanded, disabled: disabled }"
    @show="onShow"
    @hide="onHide"
    @interface="$emit('interface', $event)"
  >
    <template #trigger>
      <div class="input-box">
        <span
          v-if="hasNoValue"
          class="placeholder"
        >{{ placeholder }}</span>
        <span
          v-else
          class="placeholder"
        >
          {{ selectedDisplay }}
        </span>
        <Icon
          :name="isExpanded ? 'arrow' : 'arrow-down'"
          class="arrow-icon"
          :size="12"
        />
      </div>
    </template>
    <template #content>
      <div class="content">
        <slot name="header" />
        <div
          v-if="items.length === 0"
          class="no-items"
        >
          {{ noItemsText }}
        </div>
        <div
          v-for="item in sortedItems"
          :key="item.value"
        >
          <slot
            :item="item"
            :is-selected="itemIsSelected(item.value)"
            :toggle="() => toggleItem(item.value, item.readOnly)"
          >
            <div
              class="dropdown-item"
              :class="{
                'dropdown-item--selected': itemIsSelected(item.value),
                'dropdown-item--readonly': item.readOnly,
              }"
              @click="toggleItem(item.value, item.readOnly)"
            >
              <div
                class="checkbox"
                :class="{ 'checkbox-multi': maxSelections > 1 }"
              >
                <Icon
                  class="checkmark"
                  name="checkmark"
                  :size="14"
                />
              </div>
              <span class="item-label">{{ item.label }}</span>
              <!--<div
                v-if="!disableDeselect && itemIsSelected(item.value) || contentWidth === 'auto'"
                class="cancel-icon-container"
              >
                <Icon
                  v-if="itemIsSelected(item.value)"
                  name="times"
                  class="cancel-icon"
                  :class="{ 'cancel-icon--readonly': item.readOnly }"
                />
              </div> -->
            </div>
          </slot>
        </div>
      </div>

      <slot name="footer">
        <div
          v-if="clearable"
          class="footer-inner"
        >
          <ButtonV2
            type="text"
            color="grey"
            :disabled="!hasActiveItems"
            data-cy="cmp-dropdown-modal-clear"
            :class="{ 'clear-button--active': hasActiveItems }"
            @click="clearSelection"
          >
            {{ $t("shared.dropdown.clear") }}
          </ButtonV2>
        </div>
      </slot>
    </template>
  </Popover>
</template>

<script>
import Popover from './Popover.vue';
import Icon from './Icon.vue';
import ButtonV2 from './ButtonV2.vue';

function itemsValidator(items) {
  return items.every(
    (item) => typeof items === 'object'
      && items !== null
      // eslint-disable-next-line no-prototype-builtins
      && item.hasOwnProperty('label')
      // eslint-disable-next-line no-prototype-builtins
      && item.hasOwnProperty('value'),
  );
}

function valueValidator(value) {
  return (
    Array.isArray(value) || typeof value === 'string' || typeof value === 'number' || value === null
  );
}

export default {
  compatConfig: {
    MODE: 3,
  },
  name: 'SelectionDropdown',
  components: {
    ButtonV2,
    Icon,
    Popover,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    disableDeselect: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    noItemsText: {
      type: String,
      default: '',
    },
    noPadding: {
      type: Boolean,
    },
    anchorRight: {
      type: Boolean,
    },
    anchorTop: {
      type: Boolean,
    },
    offsetX: {
      type: String,
      default: '0',
    },
    offsetY: {
      type: String,
      default: '0',
    },
    hideOnClickInside: {
      type: Boolean,
    },
    maxSelections: {
      type: Number,
      default: 99999,
    },
    items: {
      type: Array,
      required: true,
      validator: itemsValidator,
    },
    contentWidth: {
      type: String,
      default: 'auto',
    },
    contentMaxHeight: {
      type: String,
      default: 'auto',
    },
    modelValue: {
      required: true,
      validator: valueValidator,
    },
    placeholder: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'show', 'hide', 'interface'],
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    hasActiveItems() {
      return this.isMultiSelect ? this.modelValue.length > 0 : this.modelValue !== null;
    },
    sortedItems() {
      return this.sortItems(this.items);
    },
    hasNoValue() {
      if (this.isMultiSelect) {
        return !this.modelValue.length;
      }
      return !this.modelValue;
    },
    isMultiSelect() {
      return Array.isArray(this.modelValue);
    },
    selectedDisplay() {
      if (this.isMultiSelect) {
        return this.modelValue.reduce((acc, cur, i) => {
          const currentItem = this.items.find((item) => item.value === cur);
          if (i === 0) {
            return currentItem.label;
          }
          return `${acc}, ${currentItem.label}`;
        }, '');
      }
      if (this.modelValue) {
        const selected = this.items.find((item) => item.value === this.modelValue);
        return selected ? selected.label : 'invalid value';
      }
      return null;
    },
  },
  methods: {
    onShow() {
      if (this.disabled) return;
      this.$emit('show');
      this.sortItems();
      this.isExpanded = true;
    },
    onHide() {
      this.$emit('hide');
      this.isExpanded = false;
    },
    sortItems() {
      if (this.disableSort) {
        return [...this.items];
      }
      return this.items.reduce((acc, cur) => {
        if (this.itemIsSelected(cur.value)) {
          return [cur, ...acc];
        }
        return [...acc, cur];
      }, []);
    },
    itemIsSelected(val) {
      if (this.isMultiSelect) {
        return this.modelValue.includes(val);
      }
      return this.modelValue === val;
    },
    clearSelection() {
      this.$emit('update:modelValue', this.isMultiSelect ? [] : null);
    },
    toggleItem(value, readOnly) {
      if (readOnly) return;
      if (this.isMultiSelect) {
        if (this.modelValue.includes(value)) {
          this.$emit(
            'update:modelValue',
            this.modelValue.filter((i) => i !== value),
          );
        } else if (this.maxSelections === this.modelValue.length) {
          const newValues = [...this.modelValue];
          newValues[this.modelValue.length - 1] = value;
          this.$emit('update:modelValue', newValues);
        } else {
          this.$emit('update:modelValue', [...this.modelValue, value]);
        }
      } else if (this.modelValue === value) {
        if (!this.disableDeselect) {
          this.$emit('update:modelValue', null);
        }
      } else {
        this.$emit('update:modelValue', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.popover {
  width: 230px;
  height: 48px;
}

.popover:focus,
.expanded {

  .input-box {
    border: 1px solid $color-core-cta-brand;
    box-shadow: 0px 0px 1px 2px rgba(58, 74, 235, .3);
  }
}

.disabled {

  .input-box {
    opacity: .3;
    cursor: default !important;
    pointer-events: none;
    box-shadow: none !important;
    border: 1px solid $color-grey-tinted-300 !important;
  }
}

.input-box {
  padding: 0 16px;
  border: 1px solid $color-grey-tinted-300;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    border: 1px solid $color-grey-tinted-900;
  }
}

.placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  user-select: none;
}

.placeholder,
.arrow-icon,
.no-items {
  @include font-body-14-normal;
  color: $color-grey-tinted-500;
}

.no-items {
  font-style: italic;
}

.arrow-icon {
  margin-left: auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
}

.dropdown-item {
  @include font-body-14-normal;
  color: $color-grey-tinted-900;
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 0 8px;
  cursor: pointer;
  border: 1px solid white;
  justify-content: space-between;

  .item-label {
    flex: 1;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    background-color: $color-ui-interactive-grey-lighter;
  }

  .cancel-icon-container {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .cancel-icon--readonly {
      opacity: 0;
    }
  }

  &--selected {

    .checkbox {
      background: $color-core-cta-brand;
      border-color: $color-core-cta-brand;

      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .checkbox-multi {
      display: flex;
      justify-content: center;
      align-items: center;

      .checkmark {
        display: block;
        color: white;
      }

      &::after {
        display: none;
      }
    }
  }

  &--readonly {
    background-color: $color-grey-tinted-100;
  }
}

.checkbox {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border: 1px solid $color-grey-tinted-300;
  position: relative;

  .checkmark {
    display: none;
  }

  &-multi {
    border-radius: 4px;
  }
}

.footer-inner {
  margin-top: 16px;
  padding-top: 8px;
  display: flex;
  border-top: 1px solid $color-grey-tinted-100;
  user-select: none;
}
</style>
