<template>
  <div v-if="showFeature">
    <slot name="default"> </slot>
  </div>
</template>

<script>
export default {
  name: 'Feature',
  props: {
    on: {
      type: [String, Array],
      default: null,
    },
    off: {
      type: [String, Array],
      default: null,
    },
  },
  computed: {
    showFeature() {
      let show = true;
      if (this.on) {
        const onFlags = Array.isArray(this.on) ? this.on : [this.on];
        onFlags.forEach((flag) => {
          if (!this.$store.getters['split/canAccess'](flag)) {
            show = false;
          }
        });
      }
      if (this.off) {
        const onFlags = Array.isArray(this.off) ? this.off : [this.off];
        onFlags.forEach((flag) => {
          if (this.$store.getters['split/canAccess'](flag)) {
            show = false;
          }
        });
      }
      return show;
    },
  },
};
</script>
