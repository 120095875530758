import User from '@/store/models/User';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    team: [],
  },
  getters: {
    list(state) {
      return state.team.map((user) => new User(user));
    },
    single: (state) => ({ id }) => {
      const user = state.team.find((u) => u.id === id);

      return user ? new User(user) : null;
    },
  },
  mutations: {
    set_invites(state, users) {
      state.team = users;
    },
    set_invite(state, user) {
      state.team = [...state.team.filter((u) => u.id !== user.id), user];
    },
  },
  actions: {
    get_list({ commit }) {
      return api.get('/team', {}).then(({ data }) => {
        commit('set_invites', data.data);
      });
    },
    get_single({ commit }, { id }) {
      return api.get(`/team/${id}`).then(({ data }) => {
        commit('set_invite', data.data);

        return data.data;
      });
    },
    invite(context, email) {
      return api.post('/team', { email });
    },
    resendInvite(context, id) {
      return api.post(`/team/${id}/resend`);
    },
    inviteUsers({ dispatch }, { emails, filterSubmittedEmails }) {
      return new Promise((resolve, reject) => {
        let additions = emails.length;
        const response = { data: { success: [], errors: [] } };

        emails.forEach((email, index) => {
          dispatch('invite', email)
            .then((invite) => {
              response.data.success.push({ index, item: invite });
            })
            .catch((error) => {
              response.data.errors.push({
                index,
                errors: error.response.data.errors,
              });
            })
            .finally(() => {
              additions -= 1;

              if (!additions) {
                if (response.data.errors.length) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  reject({ response });
                  filterSubmittedEmails(response.data.success);
                } else {
                  resolve(response);
                }
              }
            });
        });
      });
    },
  },
};
