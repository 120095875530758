<template>
  <Toaster @interface="bindToasterInterface" :bugsnag-client="Bugsnag" />
</template>

<script setup>
import { Toaster } from '@officeguru/components-vue3';
import { getCurrentInstance } from 'vue';
import Bugsnag from '@bugsnag/js';
import { useToaster } from '@/composables/use-toaster';

const { setToasterInterface } = useToaster();

function bindToasterInterface(toasterInterface) {
  getCurrentInstance().appContext.config.globalProperties.$toast = toasterInterface;
  setToasterInterface(toasterInterface);
}
</script>
