import { createApiInstance } from '@/store/plugins/api';

// returns a new axios api instance which is pre-loaded with an interceptor for
// verifying recaptcha v3 requests
// eg. captchaApi('my_captcha_action').post('some/endpoint')
function captchaApi(action) {
  const apiInstance = createApiInstance();
  apiInstance.interceptors.request.use(
    (request) => {
      return new Promise((resolve) => {
        // During e2e tests captcha is disabled from the api
        // So we disable it here to avoid wasting calls
        if (import.meta.env.NODE_ENV === 'e2e') {
          resolve(request);
          return;
        }
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(import.meta.env.VITE_RECAPTCHA_KEY, {
              action,
            })
            .then((token) => {
              // eslint-disable-next-line no-param-reassign
              request.headers['X-RECAPTCHA-TOKEN'] = token;
              // eslint-disable-next-line no-param-reassign
              request.headers['X-RECAPTCHA-ACTION'] = action;
              resolve(request);
            });
        });
      });
    },
    (error) => error
  );
  return apiInstance;
}

export default captchaApi;
