import Store from '../store';

function registerSplitDirective(app) {
  app.directive('split', {
    inserted(el, bindings, vnode) {
      const originalDisplay = vnode.elm.style.display;
      // eslint-disable-next-line no-param-reassign
      vnode.elm.style.display = 'none';
      if (
        !Store.getters['split/canAccess'](bindings.arg, bindings.value || 'on')
      ) {
        vnode.elm.parentElement.removeChild(vnode.elm);
      } else {
        // eslint-disable-next-line no-param-reassign
        vnode.elm.style.display = originalDisplay || 'block';
      }
    },
  });
}

export { registerSplitDirective };
