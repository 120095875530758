<template>
  <ConversationsListPreview
    data-cy="view-conversations-item"
    :title="participantName"
    :task-name="taskName"
    :date="message ? message.created_at : undefined"
    :unread="isUnread"
    :excerpt="excerpt"
    :selected="isSelected"
    :author="senderName"
    :hide-excerpt="!message"
    :service="service"
    :is-support="conversation.is_support"
    :is-verified="isVerifiedPartner || null"
    :show-unhandled="showUnhandled"
    :is-direct="isDirect"
  />
</template>

<script>
import {
  participantName,
  taskName,
  messageText,
  senderName,
  writtenByCurrentUser,
  isUnread,
  isVerifiedPartner,
} from '@officeguru/webapp-shared/src/helpers/conversation';
import ConversationsListPreview from './conversations-list-preview.vue';

export default {
  name: 'ConversationsListItem',
  components: {
    ConversationsListPreview,
  },
  props: {
    conversation: { type: Object, required: true },
    useHandling: { type: Boolean },
  },
  computed: {
    showUnhandled() {
      return this.handlingFeatureEnabled
        && this.useHandling
        && (!this.conversation.handled || !this.conversation.handled.user);
    },
    handlingFeatureEnabled() {
      return this.$store.getters['split/canAccess'](
        'vendor-handle-conversations',
      );
    },
    isVerifiedPartner() {
      return isVerifiedPartner(this.conversation, this.$appName);
    },
    isUnread() {
      return isUnread(this.conversation, this.meId);
    },
    writtenByCurrentUser() {
      return writtenByCurrentUser(this.conversation, this.meId);
    },
    isSelected() {
      return this.conversation.is_support
        ? this.$route.params.id === 'support'
        : this.$route.params.id === this.conversation.id;
    },
    excerpt() {
      return messageText(this.conversation, this.$t.bind(this));
    },
    participantName() {
      return participantName(this.conversation, this.$appName, this.$t.bind(this));
    },
    taskName() {
      return taskName(this.conversation, this.$t.bind(this));
    },
    task() {
      return this.conversation.task;
    },
    isDirect() {
      return !!this.task?.og_direct;
    },
    service() {
      return this.task?.service || {};
    },
    meId() {
      return this.$store.state.users.meId;
    },
    message() {
      // eslint-disable-next-line camelcase
      return this.conversation?.latest_log;
    },
    senderName() {
      return senderName(this.conversation, this.meId, this.$t.bind(this));
    },
  },
};
</script>

<style lang="scss" scoped></style>
