import BaseModel from '@/store/models/BaseModel';
import moment from 'moment-timezone';

const HIDDEN = [
  'thumbnail',
  'location',
  'created_at',
  'contracts_count',
  'customer',
  'questions',
  'answers',
  'pricing',
  'start_date',
  'end_date',
  'orders',
  'history',
  'files',
  'orders_lines',
];
export default class LeadContract extends BaseModel {
  #getters;

  constructor(props, getters) {
    super(props, HIDDEN);
    this.getters = getters;
  }

  get task() {
    const thumbnail = super.get('thumbnail');
    const location = super.get('location');
    const zip = super.get('zip');
    const city = super.get('city');
    const name = super.get('name');
    const formattedCreatedAtDate = new Date(
      super.get('created_at')
    ).toLocaleString(this.getters['settings/locale'], {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const status = super.get('status');
    const contractsCount = super.get('contracts_count');
    const user = super.get('user');
    const contact = super.get('contact');
    const questions = super.get('questions');
    const answers = super.get('answers');

    return {
      service: {
        thumbnail,
        name,
      },
      location: {
        address: location,
        zip,
        city,
      },
      formattedCreatedAtDate,
      status,
      contractsCount,
      customer: {
        ...super.get('customer'),
        type: 'customer',
      },
      user,
      contact,
      task_answers: [
        {
          answers,
          questions,
        },
      ],
    };
  }

  get settings() {
    return {
      pricing: super.get('pricing'),
      files: super.get('files'),
      schedules: super.get('schedules'),
      order_lines_sum: super.get('order_lines_sum', {}),
    };
  }

  get startDate() {
    const date = super.get('start_date');
    return date ? moment.utc(date) : null;
  }

  get endDate() {
    const date = super.get('end_date');
    return date ? moment.utc(date) : null;
  }

  get scheduledOrders() {
    return super.get('orders');
  }

  get orderLines() {
    return super.get('order_lines');
  }

  get history() {
    return super.get('history').map((item) => {
      return {
        ...item,
        user: {
          name: item.user,
        },
      };
    });
  }
}
