<template>
  <div
    class="toast-controller"
    data-cy="toast-controller"
  >
    <div
      v-for="toast in toasts"
      :key="toast.id"
      class="toast"
      :class="[toast.mainClass, {'disappear': toast.fading}]"
      data-cy="toast"
    >
      <!-- Type icon -->
      <div
        data-cy="toast-icon-box"
        class="type-icon-box"
        :class="toast.iconBoxClass"
      >
        <Icon
          data-cy="toast-icon"
          :size="12"
          :name="toast.icon"
        />
      </div>
      <div
        class="toast-text"
        data-cy="toast-text"
      >
        {{ toast.text }}
      </div>
      <Icon
        v-if="!toast.noCancel"
        data-cy="toast-close-icon"
        name="times"
        :size="22"
        class="close-icon"
        @click="removeToast(toast.id)"
      />
    </div>
  </div>
</template>

<script setup>
import {
  computed, defineProps, defineEmits, ref, onMounted,
} from 'vue';
import Icon from './Icon.vue';
import { useToaster } from '../composables/use-toaster';

const props = defineProps({
  bugsnagClient: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['interface']);

const toasts = ref([]);

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}

function typeProperties(toast) {
  if (toast.type === 'error') {
    return {
      icon: 'thick-cross',
      iconBoxClass: 'type-icon-box--error',
      mainClass: 'toast--error',
    };
  }

  if (toast.type === 'info') {
    return {
      icon: 'info-i',
      iconBoxClass: 'type-icon-box--info',
      mainClass: 'toast--info',
    };
  }

  /* defaults to success */
  return {
    icon: 'thick-checkmark',
    iconBoxClass: 'type-icon-box--success',
    mainClass: 'toast--success',
  };
}

function validateToastConfig(config) {
  if (config.duration === 0 && config.noCancel) {
    throw new Error('You cannot have a toast with duration 0 and noCancel');
  }
  Object.entries(config).forEach(([key, value]) => {
    const validKeys = ['text', 'type', 'duration', 'noCancel'];
    if (!validKeys.includes(key)) {
      props.bugsnagClient.notify(
        `Invalid key ${key} in toast config, valid keys are ${validKeys.join(', ')}`,
        {
          metaData: {
            config,
          },
        },
      );
    }
    if (key === 'duration' && typeof value !== 'number') {
      props.bugsnagClient.notify('Duration must be a number', {
        metaData: {
          config,
        },
      });
    }
    if (key === 'noCancel' && typeof value !== 'boolean') {
      props.bugsnagClient.notify('noCancel must be a boolean', {
        metaData: {
          config,
        },
      });
    }
    const supportedTypes = ['error', 'success', 'info'];
    if (key === 'type' && !supportedTypes.includes(value)) {
      this.bugsnagClient.notify(`type must be either ${supportedTypes.join(', ')} or undefined`, {
        metaData: {
          config,
        },
      });
    }
  });
}

function removeToast(id) {
  const index = toasts.value.findIndex((toast) => toast.id === id);
  if (index === -1) return;
  setTimeout(() => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  }, 300);
  toasts.value[index].fading = true;
}

function addToast(config) {
  validateToastConfig(config);
  const id = generateUniqueId();
  toasts.value.push({
    id,
    ...config,
    ...typeProperties(config),
  });
  if (config.duration !== 0) {
    setTimeout(() => {
      removeToast(id);
    }, config.duration || 5000);
  }
}

const toasterInterface = computed(() => ({
  add: addToast,
  remove: removeToast,
}));

function exposeInterface() {
  emit('interface', toasterInterface.value);
}

onMounted(() => {
  exposeInterface();
  useToaster().setToasterInterface(toasterInterface.value);
});
</script>

<style lang="scss" scoped>
@import "../style/main";

.toast-controller {
  position: fixed;
  top: 16px;
  right: 0;
  z-index: 10100;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  gap: 8px;
}

.toast {
  color: #fff;
  min-width: 300px;
  max-width: 100vw;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
  pointer-events: auto;
  animation: .2s appear linear forwards;

  &--success {
    background-color: #e4f7f3;
    border: 1px solid rgba(0, 145, 116, .2);

    .toast-text {
      color: $color-semantic-positive-900;
    }
  }

  &--error {
    background-color: $color-semantic-error-light;
    border: 1px solid rgba(212, 49, 79, .2);

    .toast-text {
      color: $color-semantic-negative-900;
    }
  }

  &--info {
    background-color: $color-semantic-info-50;
    border: 1px solid $color-semantic-info-200;

    .toast-text {
      color: $color-semantic-info-900;
    }
  }
}

.toast-text {
  @include font-body-14-medium;
  color: $color-grey-tinted-900;
  flex: 1;
  word-break: break-word;
  max-width: 90ch;
}

.type-icon-box {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  &--success {
    background-color: $color-semantic-success;
  }

  &--error {
    background-color: $color-semantic-error;
  }

  &--info {
    background-color: $color-semantic-info-600;
  }
}

.close-icon {
  color: $color-grey-tinted-500;
  cursor: pointer;
  margin-left: 12px;
}

.disappear {
  animation: .3s disappear linear forwards;
}

@keyframes appear {

  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes disappear {

  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
</style>
