import axios from 'axios';
import api from '@/store/plugins/api';

// eslint-disable-next-line import/no-mutable-exports
let createSubaccountInvitationCancel = () => {};
function createSubaccountInvitation(payload, precognition = false) {
  return api.post(`/customer/officehub/sub-accounts`, payload, {
    cancelToken: new axios.CancelToken(function executor(c) {
      createSubaccountInvitationCancel = c;
    }),
    headers: {
      ...(precognition ? { Precognition: 'true' } : {}),
    },
  });
}

function getSubAccounts({ slim = false } = {}) {
  return api.get('/customer/officehub/sub-accounts', {
    params: {
      ...(slim ? { resource: 'slim' } : {}),
    },
  });
}

function getImpersonationModeToken(id) {
  return api.get(`/customer/officehub/sub-accounts/${id}/login-as`);
}

function getNonImpersonationModeToken() {
  return api.get(`/customer/officehub/sub-accounts/logout`);
}

function getSubAccountPublic({ token }) {
  return api.get(`/public/sub-account/${token}`);
}

function saveSubAccountPublic({ token, payload }) {
  return api.post(`/public/sub-account/${token}`, payload);
}

function getLunchOverview({ date } = {}) {
  return api.get('/customer/officehub/lunch', {
    params: {
      date,
    },
  });
}

function getLunchOverviewExport(date) {
  return api.get('/customer/officehub/lunch/export', {
    responseType: 'blob',
    params: {
      date,
    },
  });
}

async function getPartners() {
  return api.get('/customer/officehub/partners');
}

function toggleOfficehub() {
  return api.post('/customer/officehub/toggle');
}

export {
  createSubaccountInvitation,
  createSubaccountInvitationCancel,
  getSubAccounts,
  getImpersonationModeToken,
  getNonImpersonationModeToken,
  getSubAccountPublic,
  getLunchOverview,
  saveSubAccountPublic,
  getLunchOverviewExport,
  getPartners,
  toggleOfficehub,
};
