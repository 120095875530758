import api from '../plugins/api';

// Note: All logs handling EXCEPT `latest_log` (because we need it for
// the excerpts in the conversations list) has been removed
// from this module and moved to the `conversation-logs` module.
export default {
  namespaced: true,
  state: {
    conversations: [],
    listMeta: {},
    unreadCount: 0,
    editorDrafts: {
      // conversationId: { files: [], delta: {}, selection: {} },
    },
  },
  getters: {
    editorDraft(state) {
      return (conversationId) => state.editorDrafts[conversationId];
    },
    list(state) {
      return state.conversations;
    },
    listMeta(state) {
      return state.listMeta;
    },
    single(state) {
      return ({ id }) => {
        const conversation = state.conversations.find((c) =>
          id === 'support' ? c.is_support : c.id === id
        );
        return { ...(conversation || null) };
      };
    },
    unreadCount(state) {
      return state.unreadCount;
    },
  },
  mutations: {
    clearList(state) {
      state.conversations = [];
      state.listMeta = {};
    },
    setList(state, { conversations, startFresh }) {
      if (startFresh) {
        state.conversations = [...conversations];
        return;
      }

      const newConversations = conversations.filter((newConversation) => {
        return !state.conversations.find((oldConversation) => {
          return oldConversation.id === newConversation.id;
        });
      });

      state.conversations = [...state.conversations, ...newConversations];
    },
    setListMeta(state, meta) {
      state.listMeta = meta;
    },
    addSingle(state, conversation) {
      const index = state.conversations.findIndex(
        (conv) => conv.id === conversation.id
      );

      // not found - just add to the end
      if (index === -1) {
        state.conversations = [...state.conversations, { ...conversation }];
        return;
      }

      // endpoint for support conversation does not return latest_log - keep it
      // if we already have it
      if (conversation.is_support) return;

      // found - replace at exact position
      const updatedConversations = [...state.conversations];
      updatedConversations[index] = conversation;
      state.conversations = [...updatedConversations];
    },
    updateLatestLog(state, { conversationId, log }) {
      const index = state.conversations.findIndex(
        (conversation) => conversation.id === conversationId
      );

      const updatedConversations = [...state.conversations];
      updatedConversations[index] = {
        ...state.conversations[index],
        latest_log: log,
      };

      state.conversations = [...updatedConversations];
    },
    markLatestLogRead(state, conversationId) {
      const index = state.conversations.findIndex(
        (c) => c.id === conversationId
      );

      if (index === -1 || !state.conversations[index].latest_log) return;

      // mark the conversations latest_log as read
      const updatedFoundConversation = {
        ...state.conversations[index],
        latest_log: {
          ...state.conversations[index].latest_log,
          read: true,
        },
      };

      // put the conversation back in
      const updatedConversations = [...state.conversations];
      updatedConversations[index] = { ...updatedFoundConversation };

      state.conversations = [...updatedConversations];
    },
    setUnreadCount(state, unreadCount) {
      state.unreadCount = unreadCount;
    },
    saveEditorDraft(state, { conversationId, draft }) {
      state.editorDrafts = {
        ...state.editorDrafts,
        [conversationId]: { ...draft },
      };
    },
    clearEditorDraft(state, { conversationId }) {
      state.editorDrafts = {
        ...state.editorDrafts,
        [conversationId]: undefined,
      };
    },
  },
  actions: {
    get_list(
      { commit, state },
      { requestManager, queryManager, resetPage } = {}
    ) {
      if (!requestManager) {
        return api.get('customer/conversations').then(({ data }) => {
          commit('setList', { conversations: data.data });
        });
      }

      const withActiveQueries = queryManager.hasActiveQueries();
      return requestManager
        .request('customer/conversations', {
          per_page: 15,
          resource: 'slim',
          ...queryManager.getSerializedQueries(),
          ...(resetPage || typeof state.listMeta.current_page !== 'number'
            ? { page: 1 }
            : { page: state.listMeta.current_page + 1 }),
        })
        .then(({ data, meta }) => {
          commit('setListMeta', { withActiveQueries, ...meta });
          commit('setList', { conversations: data, startFresh: resetPage });
        });
    },
    get_single({ commit }, { id, isPublic }) {
      if (!id) return null;

      if (isPublic) {
        return api.get(`/public/conversations/${id}`).then(({ data }) => {
          commit('addSingle', data.data);
        });
      }

      return api
        .get(
          id === 'support'
            ? '/conversations/support'
            : `/customer/conversations/${id}`
        )
        .then(({ data }) => {
          commit(
            'addSingle',
            Array.isArray(data.data) ? data.data[0] : data.data
          );
        });
    },
    saveEditorDraft({ commit }, options) {
      if (!(options.draft && options.conversationId)) return;

      commit('saveEditorDraft', options);
    },
    async getUnread({ commit }) {
      try {
        const response = await api.get('conversations/unread', {});
        commit('setUnreadCount', response.data);
      } catch (e) {
        if (e.response?.status !== 401) {
          throw e;
        }
        // needs to catch or navigation to restricted pages will cause e2e tests to fail
      }
    },
    async read({ commit }, { id, isSupport = false, isPublic = false }) {
      if (!id) return;

      const endpoint = `${isPublic ? '/public' : ''}/conversations/${
        isSupport ? 'support/' : ''
      }${id}/read`;

      const response = await api.post(endpoint);

      commit('markLatestLogRead', response.data.data.id);
      commit(
        'conversationLogs/markAllLogsRead',
        { id: response.data.data.id },
        { root: true }
      );
    },
    // eslint-disable-next-line consistent-return
    async updateLatestLog(
      { getters, state, dispatch, commit },
      { conversationId, log } = {}
    ) {
      // read https://office-guru.slack.com/archives/GAYR70ZEK/p1651641677830179?thread_ts=1651636321.605349&cid=GAYR70ZEK
      // if you want to understand the why behind the following conditions :)
      const withActiveQueries = state.listMeta.withActiveQueries || false;
      const conversation = getters.single({ id: conversationId });
      const hasConversation = !!Object.keys(conversation).length;

      if (hasConversation) {
        return commit('updateLatestLog', { conversationId, log });
      }

      if (!hasConversation && !withActiveQueries) {
        await dispatch('get_single', { id: conversationId });
        // eslint-disable-next-line consistent-return
        return;
      }

      // no conversation but active queries - ignored for now
      console.warn(
        'store/modules/conversations: Got a latest log (from the websocket) but filters were set and we did not know if the conversation would fit or not and therefore ignored it.'
      );
    },
  },
};
