import { APP_NAMES } from '../configuration/app-names.js';

function participantName(conversation, appName, $t) {
  if (!conversation) return '';
  if (conversation.is_support) return $t('shared.your_officeguru');
  if (appName === APP_NAMES.CUSTOMER) return conversation.partner?.name || '';
  if (appName === APP_NAMES.PARTNER) return conversation.task?.customer?.name || conversation.customer?.name || '';
  return '';
}

function taskName(conversation, $t) {
  if (!conversation) return '';

  return !conversation.is_support
    ? conversation.task?.service?.name || ''
    : $t('shared.general_questions');
}

function messageContent(conversation) {
  if (!conversation.latest_log?.content) return '';

  return JSON.parse(conversation.latest_log.content);
}

function messageText(conversation, $t) {
  const tempNode = document.createElement('div');
  const latestLogContent = messageContent(conversation);
  tempNode.innerHTML = latestLogContent?.message || '';

  return (
    // eslint-disable-next-line no-use-before-define
    addedFileText(tempNode, latestLogContent, $t) || tempNode.innerText || ''
  );
}

function addedFileText(tempNode, latestLogContent, $t) {
  if (
    !tempNode.innerText &&
    Array.isArray(latestLogContent?.files) &&
    latestLogContent.files.length > 0
  ) {
    return $t('shared.added_file');
  }
  return '';
}

function senderName(conversation, meId, $t) {
  if (!meId) throw Error('Pass meId');

  const user = conversation?.latest_log?.user;

  if (user === null || typeof user !== 'object') return '';

  if (user.id === meId) return $t('shared.me');

  return user.name.split(' ')[0];
}

function writtenByCurrentUser(conversation, meId) {
  if (!meId) throw Error('Pass meId');

  return meId === conversation?.latest_log?.user_id;
}

function isUnread(conversation, meId) {
  if (!conversation?.latest_log) return false;
  if (writtenByCurrentUser(conversation, meId)) return false;
  return !conversation.latest_log.read;
}

function sortConversations(conversations) {
  return [...conversations]
    .filter((c) => !!c.latest_log)
    .sort((a, b) => {
      const dA = a.latest_log.created_at;
      const dB = b.latest_log.created_at;
      if (dA > dB) return -1;
      if (dA < dB) return 1;
      return 0;
    });
}

function goToFirstConversation(sortedConversations, $router) {
  if (!sortedConversations.length) return;

  const [firstConversation] = sortedConversations;

  let { id } = firstConversation;
  if (firstConversation.is_support) {
    id = 'support';
  }

  $router.replace({
    name: 'Conversation',
    params: { id },
  });
}

function isVerifiedPartner(conversation, appName) {
  if (!appName) {
    throw new Error('Missing app name');
  }

  // only customers can have verified partners
  if (appName !== APP_NAMES.CUSTOMER) {
    return false;
  }

  return typeof conversation.partner?.is_verified === 'boolean'
      ? conversation.partner.is_verified
      : false;
}

export {
  participantName,
  taskName,
  messageContent,
  messageText,
  senderName,
  sortConversations,
  goToFirstConversation,
  writtenByCurrentUser,
  isUnread,
  isVerifiedPartner,
};
