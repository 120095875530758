const routes = [
  {
    path: '/services/*',
    beforeEnter: (to, from, next) => {
      next('/');
    },
  },
];

export default routes.map((route) => ({ ...route, meta: {} }));
