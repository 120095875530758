const requiredEnvironmentVariables = [
  'VITE_SPLITIO_API_KEY',
  'VITE_CF_ACCESS_CLIENT_ID',
  'VITE_CF_ACCESS_CLIENT_SECRET',
  'VITE_ATTACHMENT_URL',
  'VITE_RECAPTCHA_KEY',
  'VITE_INTERCOM_APP_ID',
  'VITE_API_URL',
  'VITE_VENDOR_APP_URL',
  'VITE_EMPLOYEE_APP_URL',
];

const missingEnvNames = requiredEnvironmentVariables.filter(
  (env) => typeof import.meta.env[env] === 'undefined'
);
if (missingEnvNames.length > 0)
  throw new Error(
    `Missing environment variable(s) ${missingEnvNames.join(', ')}`
  );
