<template>
  <div class="navigation-item-content">
    <Icon
      v-if="link.iconName"
      :name="link.iconName"
      class="menu-link-icon"
      :size="navigationIsCollapsed ? 18 : 16"
      :style="{ marginBottom: link.marginBottom || '4px' }"
    /><span
      class="menu-link-text"
      :style="!link.iconName ? 'margin-left: 29px' : ''"
    >{{ link.label }}</span><span
      v-if="link.badge && link.badge.text && link.badge.show"
      class="badge"
      :class="`badge--${link.badge.color}`"
    >{{ link.badge.text }}
    </span>
  </div>
</template>
<script>
import Icon from './Icon.vue';

export default {
  components: { Icon },
  props: {
    link: {
      type: Object,
      required: true,
    },
    navigationIsCollapsed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.navigation-collapsed {
  @include small-up {

    .menu-link {
      margin-left: 0;
      display: flex;
      justify-content: center;
    }

    .badge {
      position: absolute;
      margin-top: -26px;
    }

    .menu-link-icon {
      margin-right: 0;
    }

    .menu-link-text {
      display: none;
    }
  }
}

.navigation-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  border-radius: 9px;
  margin-left: 8px;
  padding: 1px 4px;
  min-width: 20px;
  text-align: center;

  &--red {
    @include font-caption-semi-bold;
    color: $color-grey-tinted-0;
    background: $color-misc-attention-red;
  }

  &--green {
    @include font-caption-semi-bold;
    outline: 2px solid $color-semantic-success;
    color: $color-semantic-success;
    padding: 0 4px 1px 4px;
  }

  &--gold {
    @include font-caption-medium;
    outline: 1px solid $color-core-gold;
    color: $color-core-gold;
    padding: 1px 6px;
  }
}

.menu-link {
  @include font-body-14-normal;
  color: $color-grey-tinted-300;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  text-decoration: none;
  will-change: color;
  transition: font .2s ease-in-out, color .2s ease-in-out;
  cursor: pointer;
  user-select: none;

  &--active {
    @include font-body-14-semi-bold;
    color: $color-grey-tinted-0;
  }

  &:hover {
    color: $color-grey-tinted-0;
  }
}

.menu-link-icon {
  margin-right: 12px;
}
</style>
