import Echo from 'laravel-echo';
import 'pusher-js';
import { env } from '@officeguru/webapp-shared/src/helpers/environment';

let headers = {};

if (!env.isProduction()) {
  headers = {
    'CF-Access-Client-Id': import.meta.env.VITE_CF_ACCESS_CLIENT_ID,
    'CF-Access-Client-Secret': import.meta.env.VITE_CF_ACCESS_CLIENT_SECRET,
  };
}

const connection = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_KEY || '7e15ffd4023e79dc241f',
  cluster: import.meta.env.VITE_PUSHER_CLUSTER || 'eu',
  namespace: '',
  auth: {
    headers,
  },
  authEndpoint: `${
    import.meta.env.VITE_API_URL || 'https://api-dev.officeguru.dk/'
  }broadcasting/auth`,
});

export default connection;
