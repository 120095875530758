import posthog from 'posthog-js';

class PostHog {
  posthog = null;

  identified = false;

  constructor() {
    this.posthog = posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: 'https://eu.posthog.com',
      capture_pageview: false,
    });
  }

  /**
   * Get the PostHog ID for the current user, or `null` if not initialized
   *
   * @returns {string|null}
   */
  get id() {
    if (this.initialized) {
      return this.posthog.get_distinct_id();
    }

    return null;
  }

  /**
   * The initialized state is either `true` or `undefined`
   *
   * @returns {boolean|undefined}
   */
  get initialized() {
    // eslint-disable-next-line no-underscore-dangle
    return this.posthog.__loaded;
  }

  /**
   * Identify the given users data with the UUID generated by PostHog
   *
   * @param user
   */
  identify(user) {
    if (this.initialized && !this.identified) {
      const { id } = this;
      this.posthog.identify(user.email, {
        name: user.name,
        email: user.email,
        type: user.type,
        ...(user.super_user ? {} : { company: user.customer.name }),
      });

      this.posthog.alias(user.email, id);

      this.group(user);

      this.identified = true;
    }
  }

  /**
   * Flesh out a "group" created by identifying the user
   *
   * @param user
   */
  // eslint-disable-next-line camelcase
  group({ customer, super_user }) {
    // eslint-disable-next-line camelcase
    if (this.initialized && !this.identified && !super_user)
      this.posthog.group('customer', customer.name, {
        name: customer.name,
        address: customer.address,
        zip: customer.zip,
        city: customer.city,
        cvr: customer.cvr,
        ean: customer.ean,
        lead: customer.lead,
        created_at: customer.created_at,
      });
  }

  /**
   * Capture an event in PostHog
   *
   * @param event
   * @param properties
   */
  capture(event, properties) {
    if (this.initialized) this.posthog.capture(event, properties);
  }

  /**
   * Register a pageview in PostHog
   */
  pageview() {
    this.capture('$pageview');
  }

  /**
   * Unlink the device with the identified user
   */
  reset() {
    this.posthog.reset();
  }
}
const posthogInstance = new PostHog();

export { posthogInstance as posthog };
