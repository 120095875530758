<template>
  <div style="width: 100%">
    <template v-if="link.children">
      <details :open="childIsActive(link)">
        <summary>
          <span
            class="menu-link"
            data-cy="cmp-navigation-item"
          >
            <NavigationItemContent
              :link="link"
              :navigation-is-collapsed="navigationIsCollapsed"
            />
            <Icon
              name="arrow-down-bold"
              class="menu-link-icon open-icon"
              :size="12"
              style="margin-left: auto;"
            />
          </span>
        </summary>
        <div>
          <NavigationItem
            v-for="(child, i) in link.children"
            :key="i"
            :navigation-is-collapsed="navigationIsCollapsed"
            :link="child"
            :is-child="true"
            class="child-link"
          />
        </div>
      </details>
    </template>
    <template v-else-if="link.to && link.to.indexOf('http') !== -1">
      <a
        :key="link.order"
        :href="link.to"
        class="menu-link"
        :class="{ distanced: link.distanced }"
        data-cy="cmp-navigation-item"
        :target="link.blank ? '_blank' : ''"
        :rel="link.blank ? 'noopener nofollow' : ''"
        @click="$emit('hide-mobile-navigation')"
      >
        <NavigationItemContent
          :link="link"
          :navigation-is-collapsed="navigationIsCollapsed"
        />
      </a>
    </template>
    <router-link
      v-else
      :key="link.order"
      class="menu-link"
      :to="link.to"
      :class="{ distanced: link.distanced }"
      exact-active-class="menu-link--active-exact"
      active-class="menu-link--active"
      data-cy="cmp-navigation-item"
      @click="$emit('hide-mobile-navigation')"
    >
      <NavigationItemContent
        :link="link"
        :navigation-is-collapsed="navigationIsCollapsed"
      />
    </router-link>
  </div>
</template>
<script>
import Icon from './Icon.vue';
import NavigationItemContent from './NavigationItemContent.vue';

export default {
  name: 'NavigationItem',
  components: { NavigationItemContent, Icon },
  props: {
    link: {
      type: Object,
      required: true,
    },
    navigationIsCollapsed: {
      type: Boolean,
      required: true,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hide-mobile-navigation'],
  methods: {
    isHome(to) {
      return to === '/';
    },
    childIsActive(link) {
      if (link.children) {
        return link.children.some((child) => this.$route.path.includes(child.to));
      }
      return this.$route.path.includes(link.to);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.navigation-collapsed {
  @include small-up {

    .menu-link {
      margin-left: 0;
      display: flex;
      justify-content: center;
      padding: 16px;
    }

    .badge {
      position: absolute;
      margin-top: -26px;
    }

    .menu-link-icon {
      margin-right: 0;
    }

    .menu-link-text {
      display: none;
    }
  }
}

.menu-link {
  @include font-body-14-normal;
  color: $color-grey-tinted-300;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  text-decoration: none;
  padding: 12px;
  will-change: color;
  transition: font .2s ease-in-out, color .2s ease-in-out;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;

  &--active {
    @include font-body-14-semi-bold;
    color: $color-grey-tinted-0;
  }

  &--active-exact {
    @include font-body-14-semi-bold;
    color: $color-grey-tinted-0;
  }

  &:hover {
    color: $color-grey-tinted-0;
  }
}

.menu-link-icon {
  margin-right: 12px;
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.open-icon {
  transition: all .2s;
  color: $color-grey-tinted-500;
}

details[open] summary .open-icon {
  transform: rotate(180deg);
}

.child-link a {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
</style>
