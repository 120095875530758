import axios from 'axios';
import { env } from '@officeguru/webapp-shared/src/helpers/environment';
import { logoutWithRedirect } from '@officeguru/webapp-shared/src/helpers/routing';

const url = import.meta.env.VITE_API_URL || 'https://api-dev.officeguru.dk/';

let apiOptions = {
  baseURL: url,
  headers: {
    'OG-App-Name': 'customer',
  },
};

if (!env.isProduction()) {
  apiOptions = {
    ...apiOptions,
    headers: {
      ...apiOptions.headers,
      'CF-Access-Client-Id': import.meta.env.VITE_CF_ACCESS_CLIENT_ID,
      'CF-Access-Client-Secret': import.meta.env.VITE_CF_ACCESS_CLIENT_SECRET,
    },
  };
}

// createApiInstance is used to create new instances of axios
// This is necessary because we create new instances in captcha-api.js
// To preload them with interceptors, without affecting other instances
function createApiInstance() {
  return axios.create(apiOptions);
}

const api = createApiInstance();

let authCheckIntervalId;
function startAuthCheck() {
  // only start once
  if (authCheckIntervalId) return;

  authCheckIntervalId = window.setInterval(() => {
    // when this requests gets a 401 response we're sending the user
    // to /logout because of the interceptor above
    api.head('/me');
  }, 60000);
}

function stopAuthCheck() {
  window.clearInterval(authCheckIntervalId);
}

function addUnauthorizedInterceptor(store) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // ignore 401s for logout and login because we don't want to end up at the logout page in those cases
        if (/(\/logout|\/login)/.test(error.response.config.url)) {
          return null;
        }

        logoutWithRedirect({ lang: store?.getters['users/me']?.lang });
      }
      return Promise.reject(error);
    }
  );
}

if (window.Cypress) {
  window.api = api;
}

export {
  url,
  axios,
  startAuthCheck,
  stopAuthCheck,
  createApiInstance,
  addUnauthorizedInterceptor,
};

export default api;
