import api from '@/store/plugins/api';
import Bugsnag from '@bugsnag/js';

// works unauthenticated
function getDirectInfo(token) {
  try {
    return api.get(`/public/og-direct/${token}`);
  } catch (e) {
    Bugsnag.notify(new Error('Failed to get og-direct info.'), {
      metadata: {
        error: e,
      },
    });
    return null;
  }
}

export { getDirectInfo };
