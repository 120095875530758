const routes = [
  {
    path: '/calendar',
    name: 'Calendar',
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ '@/views/calendar/pages/calendar-list.vue'
      ),
    children: [
      {
        path: ':id',
        name: 'Event',
        component: () =>
          import(
            /* webpackChunkName: "calendar" */ '@/views/calendar/pages/calendar-single.vue'
          ),
      },
    ],
  },
];

export default routes.map((route) => ({ ...route, meta: {} }));
