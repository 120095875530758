<template>
  <div v-if="advanced">
    <slot
      name="default"
      v-bind="slotData"
    />
  </div>
  <div v-else-if="isLoading || (isUpdating && loadOnUpdate)">
    <slot name="loading" />
  </div>
  <div v-else-if="isFailed">
    <slot name="failed" />
  </div>
  <div v-else>
    <slot
      name="default"
      v-bind="slotData"
    />
  </div>
</template>

<script>
export default {
  props: {
    needs: { type: Object, required: true },
    loadOnUpdate: { type: Boolean },
    advanced: { type: Boolean },
  },
  emits: ['loaded', 'data'],
  computed: {
    getters() {
      return Object.values(this.needs).map((need) => this.loaderName(need));
    },
    loadingStates() {
      return this.$store.getters['loading/states'];
    },
    isLoading() {
      return this.getters.some(
        (getter) => this.loadingStates[getter] === 'fetching',
      );
    },
    isUpdating() {
      return this.getters.some(
        (getter) => this.loadingStates[getter] === 'updating',
      );
    },
    isFailed() {
      return this.getters.some(
        (getter) => this.loadingStates[getter] === 'failed',
      );
    },
    slotData() {
      const data = {
        _updating: this.isUpdating,
        _loading: this.isLoading,
        _failed: this.isFailed,
        _refresh: this.fetchData,
      };

      Object.entries(this.needs).map(([key, val]) => {
        const getter = typeof val === 'string' ? val : val[0];
        const args = typeof val === 'string' ? null : val[1];
        if (args) {
          data[key] = this.$store.getters[getter](args);
        } else {
          data[key] = this.$store.getters[getter];
        }
        return null;
      });

      return data;
    },
  },
  watch: {
    needs: {
      handler(needs) {
        this.fetchData(needs);
      },
      immediate: true,
      deep: true,
    },
    isLoading: {
      handler(newVal, oldVal) {
        if (oldVal && !newVal) {
          this.$emit('loaded', this.slotData);
        }
      },
      immediate: true,
    },
    slotData(data) {
      this.$emit('data', data);
    },
  },
  methods: {
    fetchData(req = null) {
      const needs = req || this.needs;
      Object.values(needs).forEach((need) => {
        const getter = typeof need === 'string' ? need : need[0];
        const args = typeof need === 'string' ? {} : need[1];
        const [resource, view] = getter.split('/');
        const loaderName = this.loaderName(need);
        const status = this.loadingStates[loaderName];
        if (!['fetching', 'updating'].includes(status)) {
          const actionName = `${resource}/get_${view}`;
          const newStatus = status === 'loaded' ? 'updating' : 'fetching';
          this.$store.commit('loading/update', {
            resource: loaderName,
            status: newStatus,
          });
          this.$store
            .dispatch(actionName, args)
            .then(() => {
              this.$store.commit('loading/update', {
                resource: loaderName,
                status: 'loaded',
              });
            })
            .catch(() => {
              this.$store.commit('loading/update', {
                resource: loaderName,
                status: 'failed',
              });
            });
        }
      });
    },
    loaderName(need) {
      if (typeof need === 'string') return need;
      return need[0] + JSON.stringify(need[1]);
    },
  },
};
</script>
