import Category from '@/store/models/Category';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  getters: {
    list(state) {
      return state.categories.map((category) => new Category(category));
    },
    single: (state) => ({ id }) => {
      const category = state.categories.find((c) => c.id === id);

      return category ? new Category(category) : null;
    },
  },
  mutations: {
    set_single(state, category) {
      const categoryExists = !!state.categories.find(
        (c) => c.id === category.id
      );

      if (!categoryExists) {
        state.categories = [...state.categories, category];
        return;
      }

      // replace at exact position to make sure we don't change order of
      // the menu items as we did with the former solution that used
      // filter and destructuring (check git history)
      state.categories = [...state.categories].map((c) => {
        return c.id === category.id ? category : c;
      });
    },
    set_list(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    get_single({ commit }, { id }) {
      return api
        .get(`/categories/${id}`, {
          params: {
            with: ['services'],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);
        });
    },
    get_list({ commit }) {
      return api
        .get('/categories', {
          params: {
            with: ['services'],
          },
        })
        .then(({ data }) => {
          commit('set_list', data.data);
        });
    },
  },
};
