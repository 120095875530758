const routes = [
  {
    path: '/categories/:id',
    name: 'Category',
    component: () =>
      import(
        /* webpackChunkName: "categories" */ '@/views/categories/pages/categories-single.vue'
      ),
  },
];

export default routes.map((route) => ({ ...route, meta: {} }));
