import { ref } from 'vue';

const _interface = ref(null);

function useToaster() {
  function setToasterInterface(value) {
    _interface.value = value;
  }

  function toaster() {
    return _interface.value;
  }

  return { setToasterInterface, toaster };
}

export { useToaster };
