import BaseModel from '@/store/models/BaseModel';

export default class Offer extends BaseModel {
  constructor(props) {
    super(props);

    this.modelType = 'Offer';
  }

  static STATUS_OPEN = 'open';
}
