import ConversationsList from '@/views/conversations/conversations-list.vue';
import ConversationsSingle from '@/views/conversations/conversations-single.vue';

const routes = [
  {
    path: '/conversations',
    name: 'Conversations',
    component: ConversationsList,
    children: [
      {
        path: ':id',
        name: 'Conversation',
        component: ConversationsSingle,
      },
    ],
  },
];

export default routes;
