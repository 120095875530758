<template>
  <div
    class="editor-quill-wrap"
    :class="{ 'chat-disabled': chatDisabled || loading, 'ai-enabled': aiEnabled }"
    data-cy="view-conversations-editor"
  >
    <template v-if="aiEnabled">
      <ChatConversationAi
        :get-inner-html="getInnerHtml"
        @update="updateWithAi"
        @loading="$emit('loading', $event)"
      />
    </template>
    <div
      :id="editorId"
      class="quill"
      data-cy="editor"
    />
    <div class="actions">
      <InputFile
        v-model="editorContentLocal.files"
        class="upload"
        :public-id="publicId"
        :use-public-api="isPublic"
        :input-id="fileInputId"
        @uploading="uploading"
      />
      <ButtonV2
        v-if="!hideSendButton"
        big
        class="send-messages"
        data-cy="editor-send"
        :disabled="!canSend"
        :loading="uploadInProgress"
        @click="sendMessage"
      >
        {{ $t("shared.conversations.send") }}
      </ButtonV2>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import ButtonV2 from '../ButtonV2.vue';
import InputFile from '../input-file.vue';
import ChatConversationAi from './chat-conversation-ai.vue';

const defaultEditorContent = {
  delta: new (Quill.import('delta'))(),
  files: [],
  selection: undefined,
};

export default {
  name: 'ChatConversationEditor',
  components: {
    ButtonV2,
    InputFile,
    ChatConversationAi,
  },
  props: {
    hideSendButton: { type: Boolean, default: false },
    publicId: { type: [String, null], default: null },
    isPublic: { type: Boolean, required: false, default: false },
    editorId: { type: String, default: 'quill-editor' },
    fileInputId: { type: String, default: 'file1' },
    loading: { type: Boolean, default: false },
    editorContent: {
      required: false,
      validator(c) {
        return (
          c === undefined
          || ['files', 'delta', 'selection'].every((prop) => Object.keys(c).includes(prop))
        );
      },
      default: () => defaultEditorContent,
    },
    chatDisabled: { type: Boolean },
    aiEnabled: { type: Boolean, default: false },
  },
  emits: ['editor-content-changed', 'send-message', 'loading', 'interface'],
  setup() {
    let quillEditor;
    return { quillEditor };
  },
  data() {
    return {
      editor: null,
      uploadInProgress: null,
      // need to mutate a copy, not the reference
      editorContentLocal: { ...this.editorContent },
    };
  },
  computed: {
    canSend() {
      return (
        !this.uploadInProgress && (this.editorHasText || this.editorHasFile) && !this.chatDisabled
      );
    },
    editorHasText() {
      return !!this.editorContentLocal.delta.ops.length;
    },
    editorHasFile() {
      return !!this.editorContentLocal.files.length;
    },
  },
  watch: {
    chatDisabled: {
      handler(disabled) {
        if (disabled) {
          this.quillEditor.disable();
          this.quillEditor.setText(this.$t('shared.conversations.you_can_no_longer_chat'));
        } else {
          this.quillEditor.enable();
          this.quillEditor.setText('');
        }
      },
    },
  },
  mounted() {
    this.quillEditor = new Quill(`#${this.editorId}`, {
      theme: 'snow',
      placeholder: this.$t('shared.conversations.write_message'),
      modules: {
        toolbar: [
          ['bold', 'italic', 'strike'],
          ['link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      formats: ['bold', 'italic', 'link', 'strike', 'underline', 'indent', 'list'],
    });
    // enforce text direction to be ltr
    this.quillEditor.format('direction', 'ltr');
    this.quillEditor.format('align', 'left');

    // @see https://quilljs.com/playground/#autosave
    this.quillEditor.setContents(this.editorContentLocal.delta);
    this.quillEditor.setSelection(this.editorContentLocal.selection || Infinity);

    this.quillEditor.on(Quill.events.TEXT_CHANGE, () => {
      this.editorContentLocal.delta = this.quillEditor.getContents();
      // when changing the text, we want to remove the old selection or else
      // it could apply to our new text (and that doesn't make sense)
      this.editorContentLocal.selection = undefined;
      this.emitChangedLocalContent();
    });

    this.quillEditor.on('selection-change', (newSelection) => {
      // destroying the component results in one last selection change that
      // is null - we don't want that one
      if (!newSelection) return;

      this.editorContentLocal.selection = { ...newSelection };
      this.emitChangedLocalContent();
    });

    if (this.chatDisabled) {
      this.quillEditor.setText(this.$t('shared.conversations.you_can_no_longer_chat'));
      this.quillEditor.disable();
    }

    this.$emit('interface', {
      sendMessage: this.sendMessage,
      clear: this.clearContent,
    });
  },
  methods: {
    getInnerHtml() {
      return this.quillEditor.root.innerHTML;
    },
    updateWithAi(text) {
      this.quillEditor.root.innerHTML = text;
    },
    emitChangedLocalContent() {
      this.$emit('editor-content-changed', this.editorContentLocal);
    },
    sendMessage({ persist } = { persist: false }) {
      if (this.chatDisabled || !this.canSend) return;
      const text = this.quillEditor.root.innerHTML;
      this.$emit('send-message', {
        text,
        files: this.editorContentLocal.files.map((file) => file.url.replace(/.*\/(.*\/.*)$/, '$1')),
      });
      // @TODO: This fire and forget is a bit dangerous - if the message could
      // not be sent it is deleted anyway, leaving a sad user - we can
      // optimistically delete, but should revert in case the promise triggered
      // by `sendMessage` didn't resolve
      if (!persist) {
        this.clearContent();
      }
    },
    uploading(status) {
      this.uploadInProgress = status;
    },
    clearContent() {
      this.quillEditor.setContents([]);
      this.editorContentLocal.files = [];
      this.emitChangedLocalContent();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.editor-quill-wrap {
  padding: 24px;

  // enforce text direction to be ltr
  :deep(.ql-editor) {
    direction: ltr;
    text-align: left;
  }
}

.actions {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @include small-down {
    flex-direction: column;
  }
}

.button.send-messages {
  @include small-down {
    display: block;
    margin-top: 8px;
  }
}

.upload {
  width: 100%;
}

:deep(.dropzone) {
  border-radius: 8px;
  height: 40px;
}

.ai-enabled:deep(div.ql-toolbar.ql-snow) {
  margin-left: 42px !important;
}

:deep(div.ql-toolbar.ql-snow) {
  border: 0;
  padding: 0 0 12px 0;
}

:deep(div.ql-toolbar.ql-snow + .ql-container.ql-snow) {
  border: 1px solid #ddd;
  border-radius: 12px;
}

:deep(div.ql-container) {
  font-family: "Avenir Next", sans-serif;
  height: auto;
}

:deep(div.ql-editor) {
  height: 140px;

  :deep(div.ql-editor strong, div.ql-editor b) {
    font-weight: 600;
  }

  :deep(div.ql-editor i, div.ql-editor em) {
    font-style: italic;
  }
}

@include small-down {

  :deep(div.ql-editor) {
    height: 60px;
  }
}

.chat-disabled {
  pointer-events: none;
  user-select: none;

  & :deep(.ql-toolbar) {
    opacity: .3;
  }

  & :deep(.actions) {
    opacity: .7;
  }

  & .ql-container {
    background-color: $color-grey-tinted-75;
    height: 50px;
    border: 0 !important;
  }

  & :deep(.ql-editor p) {
    color: $color-grey-tinted-500;
  }
}
</style>
