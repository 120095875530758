import BaseModel from '@/store/models/BaseModel';
import moment from 'moment-timezone';

const HIDDEN = [];
export default class Module extends BaseModel {
  constructor(props) {
    super(props, HIDDEN);
  }

  get firstDay() {
    const date = super.get('settings', { settings: [] }).first_day;
    return date ? moment.utc(date).local() : null;
  }

  get lastDay() {
    const date = super.get('settings', { settings: [] }).last_day;
    return date ? moment.utc(date).local() : null;
  }

  get employeeModuleActivatedAt() {
    const date = super.get('settings', { settings: [] })
      .employee_module_activated_at;
    return date ? moment.utc(date).local() : null;
  }
}
