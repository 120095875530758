const ROOT_DOMAIN = window.location.host.split('.').splice(1).join('.');

const ENVIRONMENTS = {
  LOCAL: "localhost",
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

const ENVIRONMENT_URLS = {
  [ENVIRONMENTS.LOCAL]: `https://hub-dev.${ROOT_DOMAIN}`,
  [ENVIRONMENTS.DEVELOPMENT]: `https://hub-dev.${ROOT_DOMAIN}`,
  [ENVIRONMENTS.PRODUCTION]: `https://hub.${ROOT_DOMAIN}`,
};

class Environment {
  // eslint-disable-next-line class-methods-use-this
  isLocal() {
    // works for localhost and officeguru.local (Docker stuff)
    return window.location.hostname.indexOf("local") !== -1;
  }

  isDevelopment() {
    return !this.isLocal() && process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT;
  }

  isProduction() {
    return !this.isLocal() && process.env.NODE_ENV === ENVIRONMENTS.PRODUCTION;
  }

  getEnvironmentName() {
    if (this.isLocal()) {
      return ENVIRONMENTS.LOCAL;
    }
    if (this.isDevelopment()) {
      return ENVIRONMENTS.DEVELOPMENT;
    }
    if (this.isProduction()) {
      return ENVIRONMENTS.PRODUCTION;
    }
    throw new Error("Unknown environment provided");
  }

  getRootDomain() {
    return ROOT_DOMAIN;
  }

  getEnvironmentUrl() {
    return ENVIRONMENT_URLS[this.getEnvironmentName()];
  }
}

const env = new Environment();

export { env, ENVIRONMENTS };
