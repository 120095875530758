<template>
  <div>
    <ServiceIcon
      v-if="!isSupport"
      :service-name="service.name"
      :service-plain-text-id="service.plain_text_id"
      :is-verified="hasProperService || null"
      :is-direct="isDirect"
      icon-size="medium"
    />
    <ConversationsOgIcon v-else />
  </div>
</template>

<script>
import ServiceIcon from '../../../components/service-icon.vue';
import officeguruLogo from '../../../assets/officeguru_logo.svg';
import ConversationsOgIcon from './conversations-og-icon.vue';

export default {
  name: 'ConversationsListPreviewIcon',
  components: {
    ConversationsOgIcon,
    ServiceIcon,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    isSupport: {
      type: Boolean,
      default: false,
    },
    isDirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo: officeguruLogo,
    };
  },
  computed: {
    hasProperService() {
      return !!(this.service.name && this.service.plain_text_id);
    },
  },
};
</script>
