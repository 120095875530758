const WOOTRIC_SDK = 'https://cdn.wootric.com/wootric-sdk.js';

export default {
  namespaced: true,
  state: {
    loaded: false,
  },
  actions: {
    setup({ state, rootGetters }) {
      if (!state.loaded) {
        const me = rootGetters['users/me'];

        if (!me) return;

        const wootricSettings = {
          email: me.email,
          created_at: new Date(me.created_at).getTime() / 1000,
          account_token: 'NPS-e087e0cd',
          properties: {
            contact_id: me.customer?.contact?.id || null,
            company_id: me.customer?.id || null,
            user_id: me.id,
            contact: me.customer?.contact?.name || null,
            company: me.customer?.name || null,
            name: me.name,
          },
        };
        window.wootricSettings = wootricSettings;
        const tag = document.createElement('script');
        tag.setAttribute('src', WOOTRIC_SDK);
        tag.onload = () => window.wootric('run');
        document.head.appendChild(tag);

        state.loaded = true;
      }
    },
  },
};
