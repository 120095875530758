<template>
  <div class="conversations-list-empty-wrapper">
    <img
      class="image"
      :src="personCheckingMailbox"
      :alt="$t('shared.conversations.person_checking_mailbox')"
    >
    <h3 class="headline">
      {{ $t('shared.conversations.nothing_found') }}
    </h3>
    <p class="description">
      {{ $t('shared.conversations.please_try_clearing_filters') }}
    </p>
    <ButtonV2
      type="outline"
      @click="$emit('clear-filters')"
    >
      {{ $t('shared.conversations.remove_filters_and_search') }}
    </ButtonV2>
  </div>
</template>

<script>
import personCheckingMailbox from '../../../assets/person-checking-mailbox.svg';
import ButtonV2 from '../../../components/ButtonV2.vue';

export default {
  name: 'ConversationsListEmtpy',
  components: { ButtonV2 },
  emits: ['clear-filters'],
  data() {
    return {
      personCheckingMailbox,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/main";

.conversations-list-empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
}

.image {
  max-width: 104px;
  margin-bottom: 8px;
}

.headline {
  @include font-body-14-semi-bold;
  color: $color-grey-tinted-900;
  margin-bottom: 8px;
}

.description {
  @include font-body-14-medium;
  color: $color-grey-tinted-500;
  margin-bottom: 12px;
  text-align: center;
}
</style>
