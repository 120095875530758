<template>
  <div
    class="direct-tag"
    :class="{'direct-tag--small': small}"
  >
    {{ $t('shared.direct_tag') }}
  </div>
</template>

<script>
export default {
  name: 'DirectTag',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

.direct-tag {
  @include font-caption-semi-bold;
  color: $color-brand-600;
  display: inline-flex;
  height: 20px;
  padding: 0 4px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: $color-brand-600;
  background: $color-brand-100;
  box-shadow: 0 2px 2px 0 rgba(31, 14, 74, .03);

  &--small {
    @include font-caption-semi-bold;
    color: $color-brand-600;
    height: 16px;
    border-radius: 4px;
  }
}
</style>
