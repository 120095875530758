import Location from '@/store/models/Location';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    locations: [],
    requested: false,
  },
  getters: {
    list(state) {
      return state.locations.map((location) => new Location(location));
    },
    requested(state) {
      return state.requested;
    },
    hasLocations(state) {
      return state.locations.length > 0;
    },
  },
  mutations: {
    set_list(state, locations) {
      state.locations = locations;
    },
  },
  actions: {
    get_list({ commit }) {
      this.requested = true;
      return api.get('locations').then(({ data }) => {
        commit('set_list', data.data);
      });
    },
  },
};
