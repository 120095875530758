import CalendarEvent from '@/store/models/CalendarEvent';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    list(state, _1, _2, getters) {
      return ({ startDate, endDate }) =>
        state.events
          .filter((event) => event.date >= startDate && event.date <= endDate)
          .map((event) => new CalendarEvent(event, getters));
    },
    single: (state, _1, _2, getters) => ({ id }) => {
      const event = state.events.find((e) => e.id === id);

      return event ? new CalendarEvent(event, getters) : null;
    },
  },
  mutations: {
    set_single(state, event) {
      const oldItem = state.events.find((e) => e.id === event.id);
      if (oldItem) {
        const index = state.events.findIndex((e) => e.id === event.id);
        state.events[index] = { ...oldItem, ...event };
      } else {
        state.events.push(event);
      }
    },
  },
  actions: {
    get_list({ commit }, { startDate, endDate }) {
      return api
        .get('/events', {
          params: {
            start_date: startDate,
            end_date: endDate,
            // @see https://officeguru.atlassian.net/browse/OG-1895?focusedCommentId=11125
            backw_comp_tz: false,
            with: ['messages.user'],
          },
        })
        .then(({ data }) => {
          data.data.forEach((event) => {
            commit('set_single', event);
          });
        });
    },
    get_single({ commit }, { id }) {
      return api
        .get(`/events/${id}`, {
          params: {
            with: ['messages.user', 'reviews', 'offers.partner'],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);
        });
    },
    sendMessage({ commit }, { id, conversationId, text, files }) {
      return api
        .post(`/events/${id}/messages`, {
          message: text,
          files,
        })
        .then(({ data }) => {
          commit(
            'conversationLogs/mergeLogs',
            { id: conversationId, logs: [data.data], action: 'append' },
            { root: true }
          );
        });
    },
    sendReview(context, { id, rating }) {
      return api.post(`/events/${id}/reviews`, { rating });
    },
  },
};
