function avatarInitials(name) {
    const initials = name.split(' ');

    const firstAndLast =
        initials.length < 2
            ? initials[0][0] || ''
            : initials[0][0] + initials[initials.length - 1][0];

    return firstAndLast.toUpperCase();
}

export { avatarInitials }