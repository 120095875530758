import BaseModel from '@/store/models/BaseModel';
import moment from 'moment-timezone';

const HIDDEN = ['first_day', 'meal_options'];
export default class Lunch extends BaseModel {
  constructor(props) {
    super(props, HIDDEN);
  }

  get firstDay() {
    const date = super.get('first_day');
    return date ? moment.utc(date).local() : null;
  }

  get mealOptions() {
    const options = super.get('meal_options') || [
      { icon: '', label: '', price: 0, amount: 0 },
    ];
    return options;
  }
}
