export default {
  namespaced: true,
  state: {
    confirmAccept: null,
    confirmReject: null,
    confirmContent: null,
    navigationHidden: false,
  },
  getters: {
    confirmAccept(state) {
      return state.confirmAccept;
    },
    confirmReject(state) {
      return state.confirmReject;
    },
    confirmContent(state) {
      return state.confirmContent;
    },
    isNavigationHidden(state) {
      return state.navigationHidden;
    },
  },
  mutations: {
    updateConfirm(state, { accept, reject, content }) {
      state.confirmAccept = accept;
      state.confirmReject = reject;
      state.confirmContent = content;
    },
    clearConfirm(state) {
      state.confirmAccept = null;
      state.confirmReject = null;
      state.confirmContent = null;
    },
  },
  actions: {
    confirm({ commit }, text) {
      return new Promise((resolve) => {
        commit('updateConfirm', {
          accept: () => {
            commit('clearConfirm');
            resolve();
          },
          reject: () => {
            commit('clearConfirm');
          },
          content: text,
        });
      });
    },
  },
};
