import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { env } from '@officeguru/webapp-shared/src/helpers/environment';
import pack from '../../../package.json';

function initBugsnag() {
  Bugsnag.start({
    apiKey: !env.isLocal()
      ? 'fb8d09ad8ded8c2b8ec999d097120b78'
      : 'just-so-we-dont-log',
    releaseStage: env.getEnvironmentName(),
    plugins: [new BugsnagPluginVue()],
    // eslint-disable-next-line global-require
    appVersion: pack.version,
  });
}

export { initBugsnag };
