import { logoutWithRedirect } from '@officeguru/webapp-shared/src/helpers/routing';

export default {
  namespaced: true,
  state: {
    loadingSteps: {
      getUser: { done: false },
      initSplit: { done: false, dependencies: ['getUser'] },
    },
    states: {},
  },
  getters: {
    states(state) {
      return state.states;
    },
    initialLoadDone(state) {
      return !Object.values(state.loadingSteps).find((step) => !step.done);
    },
  },
  mutations: {
    update(state, { resource, status }) {
      state.states[resource] = status;
    },
    loadingStepDone(state, name) {
      state.loadingSteps[name].done = true;
    },
    loadingStepStarted(state, name) {
      state.loadingSteps[name].started = true;
    },
  },
  actions: {
    startInitialLoad({ state, dispatch }) {
      Object.keys(state.loadingSteps).forEach((name) => {
        const step = state.loadingSteps[name];
        if (!step.dependencies || step.dependencies.length === 0) {
          dispatch('loadStep', name);
        }
      });
    },
    loadStep({ commit, dispatch, rootGetters }, name) {
      commit('loadingStepStarted', name);
      dispatch(`loadstep_${name}`)
        .then(() => {
          commit('loadingStepDone', name);
          dispatch('searchForMissingLoadSteps');
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            logoutWithRedirect({ lang: rootGetters['users/me']?.lang });
          }
        });
    },
    searchForMissingLoadSteps({ state, dispatch }) {
      Object.keys(state.loadingSteps).forEach((name) => {
        const step = state.loadingSteps[name];
        if (step.started || step.done) return;
        // Slightly overdefensive in this step as dependencies really shouldn't be undefined here
        const dependenciesLoaded = !(step.dependencies || []).find(
          (dependency) => !state.loadingSteps[dependency].done
        );
        if (dependenciesLoaded) {
          dispatch('loadStep', name);
        }
      });
    },
    loadstep_getUser({ dispatch }) {
      return dispatch('users/get_me', null, { root: true });
    },
    loadstep_initSplit({ dispatch, rootGetters }) {
      return dispatch('split/setup', rootGetters['users/me'].id, {
        root: true,
      });
    },
  },
};
