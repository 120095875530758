import { QueryAbstract } from './queries/query-abstract';

class QueryManager {
  constructor() {
    this._queries = [];
  }

  getSerializedQueries() {
    const queries = this._queries.flatMap(f => f.serialize());
    return queries.reduce((queries, query) => {
      queries = { ...queries, ...query };
      return queries;
    }, {});
  }

  isLimitingResults() {
    return this._queries.some(query => query.isLimitingResults() && query.isActive());
  }

  hasActiveQueries() {
    return this._queries.some(query => query.isActive());
  }

  hasActiveQueriesWithTag(tag) {
    return this._queries.filter(query => query.hasTag(tag)).some(query => query.isActive());
  }

  addQueries(queries) {
    queries.forEach(q => this.addQuery(q));

    return this;
  }

  resetQueriesValues() {
    this._queries.forEach(q => q.reset());

    return this;
  }

  resetQueryValuesByTag(tag) {
    this._queries.filter(q => q.hasTag(tag)).forEach(q => q.reset());

    return this;
  }

  addQuery(queryInterface) {
    this._queries.push(queryInterface);

    return this;
  }

  restorePersistedQueryValues(query = {}) {
    this._queries.forEach(q => q.unserialize(query));

    return this;
  }

  restorePersistedQueryValuesFromStorage(options = {}) {
    if (!options.userType) throw Error('User type is required for retrieving from storage');

    const queries = JSON.parse(localStorage.getItem(`${options.userType}-chat-list-filters`) || '{}');
    this._queries.forEach(q => q.unserialize(queries));

    return this;
  }

  persistQueryValues($router, options = {}) {
    const queries = this.getSerializedQueries();
    const queriesWithValues = Object.fromEntries(
        Object.entries(queries).filter(([_, value]) => {
          if (Array.isArray(value)) return value.length > 0;
          return ![null, undefined, ''].includes(value);
        })
    )

    // URL strategy is default
    if (!options.strategy) {
      $router.push({
        name: $router.currentRoute.name,
        query: queriesWithValues,
      })
      return this;
    }

    // LocalStorage strategy
    if (options.strategy === 'storage') {
      if (!options.userType) throw Error('User type is required for storage strategy');

      localStorage.setItem(`${options.userType}-chat-list-filters`, JSON.stringify(queriesWithValues));
      return this;
    }

    throw new Error(`Unknown persistence strategy: ${options.strategy}`);
  }
}

export { QueryManager }