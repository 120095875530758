import { createApp } from 'vue';
import { env } from '@officeguru/webapp-shared/src/helpers/environment';
import async from './components/async.vue';
import VueGtag from 'vue-gtag';
import moment from 'moment-timezone';
import feature from './components/feature.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { registerSplitDirective } from './directives/split';
import './helpers/env-check.js';
import { initBugsnag } from './store/plugins/bugsnagClient';
import Bugsnag from '@bugsnag/js';
import { APP_NAMES } from '@officeguru/webapp-shared/src/configuration/app-names';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.config.globalProperties.$moment = moment;
initBugsnag();
const bugsnagVue = Bugsnag.getPlugin('vue');
app.use(bugsnagVue);

if (env.isProduction()) {
  app.use(VueGtag, {
    config: { id: 'L0LK2VTF3V' },
  });
}

if (!window?.process?.env) {
  window.process = {
    ...(window.process ? window.process : {}),
    env: import.meta.env,
  };
}

// TODO move this somewhere more fitting
app.component('async', async);
app.component('feature', feature);

app.mount('#app');
app.config.globalProperties.$appName = APP_NAMES.CUSTOMER;
if (window.Cypress) {
  // only available during E2E tests
  window.vue_app = app;
}

registerSplitDirective(app);

const globals = app.config.globalProperties;

export { app, globals };
