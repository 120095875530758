import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    all: [],
    single: null,
  },
  getters: {
    all(state) {
      return state.all;
    },
    single(state) {
      return ({ id }) => {
        return state.single?.id === id ? state.single : null;
      };
    },
  },
  mutations: {
    set_all(state, employees) {
      state.all = employees;
    },
    set_single(state, employee) {
      const allIndex = state.all.findIndex((item) => item.id === employee.id);
      if (allIndex !== -1) {
        state.all[allIndex] = employee;
      }
      state.single = employee;
    },
  },
  actions: {
    get_all({ commit }) {
      return api.get('/employees').then(({ data }) => {
        commit('set_all', data.data);
      });
    },
    get_single({ commit }, { id }) {
      return api.get(`/employees/${id}`).then(({ data }) => {
        commit('set_single', data.data);
      });
    },
    invite({ dispatch }, formData) {
      return api.post('/employees', formData).then(() => {
        dispatch('get_all');
      });
    },
    resendInvite(context, id) {
      return api.post(`/employees/${id}/resend`);
    },
    update({ commit }, { id, formData }) {
      return api.put(`/employees/${id}`, formData).then(({ data }) => {
        commit('set_single', data.data);
      });
    },
    delete(_, { id }) {
      return api.delete(`/employees/${id}`);
    },
  },
};
