<template>
  <ConversationsSingle :ai-enabled="aiEnabled" :with-list="true" />
</template>

<script>
import { ConversationsSingle } from '@officeguru/components-vue3';

export default {
  name: 'ConversationsSinglePage',
  components: { ConversationsSingle },
  computed: {
    aiEnabled() {
      return this.$store.getters['split/canAccess'](
        'customer-conversations-ai-enabled'
      );
    },
  },
};
</script>
