import api, { axios } from '@/store/plugins/api';
import Media from '@/store/models/Media';
import captchaApi from '@/store/plugins/captcha-api';

export default {
  namespaced: true,
  state: {
    all: [],
    cancellationTokens: [],
  },
  getters: {
    single: (state) => ({ id }) => {
      const media = state.all.find((m) => m.id === id);

      return media ? new Media(media) : null;
    },
  },
  mutations: {
    set_single(state, media) {
      state.all = [...state.all.filter((m) => m.id !== media.id), media];
    },
    remove(state, media) {
      state.all.splice(
        state.all.findIndex((m) => m.id === media.id),
        1
      );
    },
    addCancellationToken(state, token) {
      state.cancellationTokens = [...state.cancellationTokens, token];
    },
    cancelAllUploads(state) {
      state.cancellationTokens.forEach((t) => {
        t.cancel(
          'Upload cancelled due to application requesting cancellation.'
        );
      });
      state.cancellationTokens = [];
    },
  },
  actions: {
    cancelAllUploads({ commit }) {
      commit('cancelAllUploads');
    },
    async publicDelete({ commit }, { media }) {
      const url = `/public/medias/${media.id}`;
      await captchaApi('delete_file').request({
        url,
        method: 'delete',
      });

      commit('remove', media);
    },
    async publicUpload({ commit }, { file, onProgress }) {
      const source = axios.CancelToken.source();
      commit('addCancellationToken', source);

      const params = new FormData();
      params.append('file', file);

      const response = await captchaApi('upload_file').request({
        cancelToken: source.token,
        url: '/public/medias',
        method: 'post',
        data: params,
        onUploadProgress(event) {
          onProgress(Math.round((event.loaded * 100) / event.total));
        },
      });

      const media = response.data.data;
      commit('set_single', media);

      return media;
    },
    async upload({ commit }, { file, onProgress, publicId, isPublic }) {
      const source = axios.CancelToken.source();
      commit('addCancellationToken', source);

      const url =
        publicId && isPublic
          ? `/public/conversations/${publicId}/medias`
          : 'medias';
      const params = new FormData();
      params.append('file', file);

      const response = await api.request({
        cancelToken: source.token,
        url,
        method: 'post',
        data: params,
        onUploadProgress(event) {
          onProgress(Math.round((event.loaded * 100) / event.total));
        },
      });

      const media = response.data.data;
      commit('set_single', media);

      return media;
    },
    async delete({ commit }, { media }) {
      const url = `medias/${media.id}`;
      await api.request({
        url,
        method: 'delete',
      });

      commit('remove', media);
    },
  },
};
