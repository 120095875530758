import {QueryAbstract} from './query-abstract';

class QueryPartners extends QueryAbstract {
  reset() {
    this._partners = [];
  }

  serialize() {
    return {
      partners: this._partners,
    }
  }

  unserialize(filter) {
    const { partners } = filter;

    if (typeof partners === 'string') this._partners = [partners];
    if (Array.isArray(partners)) this._partners = partners;
  }

  set partners(value) {
    this._partners = value;
  }

  get partners() {
    return this._partners;
  }
}

export { QueryPartners }