<template>
  <div
    data-cy="sub-navigation"
    class="subnavigation"
  >
    <div class="subnavigation__inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../style/main";

.subnavigation {
  display: flex;
  flex: 0 1 auto;
  background: #fff;
  height: 60px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  @include small-down {
    padding: 20px;
  }
}

.subnavigation__inner {
  margin: auto;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1264px;
  padding: 0 32px;
}
</style>
