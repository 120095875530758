import api, { url } from '../plugins/api';

export default {
  namespaced: true,
  state: {
    menus: {},
  },
  getters: {
    menu(state) {
      return ({ id, from, to }) => {
        if (!state.menus[id]) return undefined;
        return state.menus[id][`${from}|${to}`]; // TODO make up your mind
      };
    },
  },
  mutations: {
    set_menu(state, { id, menu, from, to }) {
      if (!state.menus[id]) {
        state.menus[id] = {};
      }
      state.menus[id][`${from}|${to}`] = menu;
    },
  },
  actions: {
    get_menu({ commit }, { id, from, to }) {
      return api
        .get(`/modules/${id}/menu`, {
          params: {
            start_date: from,
            end_date: to,
          },
        })
        .then(({ data }) => {
          commit('set_menu', { id, menu: data.data, from, to });
        });
    },
    // FIXME: This should be extracted to a seperate non-permanent (non-vuex) module
    get_export(_, { id, startDate, endDate }) {
      return api.get(
        `${url}modules/${id}/export/employee-lunch?start_date=${startDate}&end_date=${endDate}`
      );
    },
  },
};
