<template>
  <span data-cy="conversations-list-time-stamp">{{ timestring }}</span>
</template>

<script>
import { time } from '@officeguru/webapp-shared/src/helpers/date-format';

export default {
  name: 'ConversationsListTimeStamp',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    timestring() {
      const givenDate = this.$moment(this.date);
      const nowDate = this.$moment(this.now);
      const isToday = givenDate.isSame(nowDate, 'day');
      const isYesterday = givenDate.isSame(nowDate.subtract(1, 'day'), 'day');
      const isSameYear = givenDate.isSame(nowDate, 'year');

      if (isToday) {
        return time(givenDate);
      }

      if (isYesterday) {
        return `${this.$t('shared.conversations.yesterday')}, ${time(givenDate)}`;
      }

      // TODO: Properly localize when having multiple languages
      return isSameYear ? givenDate.format('D. MMM') : givenDate.format('ll');
    },
  },
  created() {
    this.ticker = setInterval(() => {
      this.now = new Date();
    }, 60000);
  },
  unmounted() {
    clearInterval(this.ticker);
  },
};
</script>
