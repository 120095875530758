import api from '../../plugins/api';
import Order from '../../models/webshop/Order';

export default {
  namespaced: true,
  state: {
    orders: [],
    meta: {},
  },
  getters: {
    list(state, _1, _2, getters) {
      return state.orders.map((order) => new Order(order, getters));
    },
    single: (state, _1, _2, getters) => (id) => {
      const order = state.orders.find((o) => o.id === id);

      return order ? new Order(order, getters) : null;
    },
    meta(state) {
      return state.meta;
    },
  },
  mutations: {
    set_list(state, list) {
      state.orders = list;
    },
    set_single(state, order) {
      const oldItem = state.orders.find((o) => o.id === order.id);
      if (oldItem) {
        const index = state.orders.findIndex((o) => o.id === order.id);
        state.orders[index] = { ...oldItem, ...order };
      } else {
        state.orders.push(order);
      }
    },
    set_meta(state, meta) {
      state.meta = meta;
    },
  },
  actions: {
    get_list(
      { commit, getters },
      { page, filter, orderBy, order, search } = {
        page: 1,
        filter: 'all',
        orderBy: 'created_at',
        order: 'DESC',
        search: '',
      }
    ) {
      return api
        .get(
          `/customer/webshop/orders?page=${page}&filter=${filter}&order_by=${orderBy}&order_dir=${order}&search=${search}`
        )
        .then(({ data }) => {
          commit('set_list', data.data);
          commit('set_meta', data.meta);
          return getters.list;
        });
    },
    get_single({ commit, getters }, id) {
      return api.get(`/customer/webshop/orders/${id}`).then(({ data }) => {
        commit('set_single', data.data);
        return getters.single(id);
      });
    },
  },
};
