import { SplitFactory } from '@splitsoftware/splitio';

let client = null;

export default {
  namespaced: true,
  state: {
    ready: false,
  },
  getters: {
    ready(state) {
      return state.ready;
    },
    canAccess: (_1, _2, _3, rootGetters) => (flag, value = 'on') => {
      const user = rootGetters['users/me'];
      const customer = rootGetters['users/customer'];
      const attributes = {
        name: user.name,
        email: user.email,
        type: 'customer',
        customer_id: customer.id,
        customer: customer.name,
        partner_id: null,
        partner: null,
        lead: customer.lead,
      };

      return client.getTreatment(flag, attributes) === value;
    },
  },
  mutations: {
    ready(state, ready) {
      state.ready = ready;
    },
  },
  actions: {
    setup({ getters, commit }, userId) {
      return new Promise((resolve) => {
        if (getters.ready) {
          resolve();
        } else {
          client = SplitFactory({
            core: {
              authorizationKey: import.meta.env.VITE_SPLITIO_API_KEY,
              key: userId,
            },
          }).client();

          client.on(client.Event.SDK_READY, () => {
            commit('ready', true);

            resolve();
          });
        }
      });
    },
    // @TODO is this maybe deprecated now?
    canAccess({ getters }, split) {
      let flag = null;
      let value = 'on';

      if (typeof split === 'string') {
        flag = split;
      } else if (Array.isArray(split)) {
        [flag, value] = split;
      }

      return getters.canAccess(flag, value);
    },
  },
};
