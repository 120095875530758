<template>
  <div
    class="info-tag"
    :class="{ ...classBinding }"
    @click="$emit('click')"
  >
    <Icon
      v-if="iconLeft"
      :name="iconLeft"
      :size="12"
      style="margin-right: 4px;"
    />
    <slot />
    <Icon
      v-if="iconRight"
      :name="iconRight"
      :size="12"
      style="margin-left: 4px;"
    />
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  compatConfig: {
    MODE: 3,
  },
  name: 'InfoTag',
  components: {
    Icon,
  },
  props: {
    small: {
      type: Boolean,
    },
    xSmall: {
      type: Boolean,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'grey',
      validator: (val) => ['grey', 'blue', 'green', 'yellow', 'red'].includes(val),
    },
  },
  emits: ['click'],
  computed: {
    colorClass() {
      return `info-tag--${this.color}`;
    },
    classBinding() {
      return {
        'info-tag-small': this.small,
        'info-tag-x-small': this.xSmall,
        [this.colorClass]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.info-tag {
  @include font-body-14-normal;
  padding: 1px 6px 0 6px;
  height: 22px;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  word-break: keep-all;

  &-small {
    @include font-caption-normal;
    height: 20px;
  }

  &-x-small {
    @include font-caption-normal;
    height: 16px;
  }

  &--blue {
    color: $color-semantic-info;
    background-color: $color-semantic-info-light;
  }

  &--green {
    color: $color-semantic-success;
    background-color: $color-semantic-success-lightest;
  }

  &--yellow {
    color: $color-semantic-warning-dark;
    background-color: $color-semantic-warning-lightest;
  }

  &--grey {
    color: $color-semantic-none-darker;
    background-color: $color-misc-ui-non-interactive-grey-light;
  }

  &--red {
    color: $color-semantic-error-dark;
    background-color: $color-semantic-error-light;
  }
}
</style>
