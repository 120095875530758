import api from '@/store/plugins/api';
import Contract from '@/store/models/Contract';
import Order from '@/store/models/Order';
import i18n from '@/i18n';
const { t } = i18n.global;
import { getOrders } from './functions/contracts-functions';

export default {
  namespaced: true,
  state: {
    all: [],
    orders: [],
    loading: {},
    declineModal: false,
  },
  getters: {
    list(state, _1, _2, getters) {
      return state.all.map((c) => new Contract(c, getters));
    },
    single: (state, _1, _2, getters) => ({ id }) => {
      const contract = state.all.find((c) => c.id === id);

      return contract ? new Contract(contract, getters) : null;
    },
    belongingToTask: (state, _1, _2, getters) => (taskId) => {
      return state.all
        .filter((c) => c.task_id === taskId)
        .map((c) => new Contract(c, getters));
    },
    orders: getOrders,
    orderLoading: (state) => ({ id, startDate, endDate }) => {
      return state.loading[`${id}${startDate}${endDate}`];
    },
    order: (state, _1, _2, getters) => ({ id }) => {
      const order = state.orders.find((o) => o.id === id);

      return order ? new Order(order, getters) : null;
    },
    orderLog: (state, _1, _2, getters) => ({ orderId, logId }) => {
      const order = new Order(
        state.orders.find((o) => o.id === orderId),
        getters
      );
      const log = order.history.find((l) => l.id === logId);

      return log;
    },
    madeByPartner: (state, _1, _2, getters) => (partnerId) => {
      return state.all
        .filter((i) => i.partner_id === partnerId)
        .map((i) => new Contract(i, getters));
    },
  },
  mutations: {
    set_single(state, contract) {
      const oldItem = state.all.find((c) => c.id === contract.id);
      if (oldItem) {
        const index = state.all.findIndex((c) => c.id === contract.id);
        state.all[index] = { ...oldItem, ...contract };
      } else {
        state.all.push(contract);
      }
    },
    set_order(state, order) {
      const oldItem = state.orders.find((o) => o.id === order.id);
      if (oldItem) {
        const index = state.orders.findIndex((o) => o.id === order.id);
        state.orders[index] = { ...oldItem, ...order };
      } else {
        state.orders.push(order);
      }
    },
    set_order_loading(state, { id, startDate, endDate, loading }) {
      state.loading = {
        ...state.loading,
        [`${id}${startDate}${endDate}`]: loading,
      };
    },
    delete_order(state, id) {
      const index = state.orders.findIndex((o) => o.id === id);

      if (index >= 0) {
        delete state.orders[index];
      }
    },
    set_declineModal(state, isOn) {
      state.declineModal = isOn;
    },
  },
  actions: {
    mark_read(_, { id }) {
      return api.post(`customer/contracts/${id}/read`);
    },
    get_list({ commit, getters }) {
      return api
        .get('/contracts', {
          params: {
            with: ['orders'],
          },
        })
        .then(({ data }) => {
          data.data.forEach((contract) => {
            commit('set_single', contract);
          });

          return getters.list;
        });
    },
    get_single({ commit, getters }, { id }) {
      return api
        .get(`/contracts/${id}`, {
          params: {
            with: [
              'orders',
              'task.service',
              'history.user',
              'settings',
              'user',
            ],
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);

          return getters.single({ id });
        });
    },
    create({ commit, getters }, contract) {
      return api.post('/contracts', contract).then(({ data }) => {
        commit('set_single', data.data);

        return getters.single(data.data);
      });
    },
    accept({ dispatch, commit }, { id: contractId }) {
      return dispatch(
        'ui/confirm',
        {
          headline: t('customer.contracts.sure_accept_offer'),
        },
        { root: true }
      ).then(() =>
        api.post(`contracts/${contractId}/approve`).then(({ data }) => {
          commit('contracts/set_single', data.data, { root: true });
        })
      );
    },
    acceptDirect({ commit }, { id: contractId }) {
      return api.post(`contracts/${contractId}/approve`).then(({ data }) => {
        commit('contracts/set_single', data.data, { root: true });
      });
    },
    // eslint-disable-next-line camelcase
    decline({ commit }, { id, decline_reason, decline_comment }) {
      return api
        .post(`/contracts/${id}/decline`, { decline_reason, decline_comment })
        .then(({ data }) => {
          commit('contracts/set_single', data.data, { root: true });
        });
    },
    dispute({ commit, getters }, { id }) {
      return api.post(`/contracts/${id}/dispute`).then(({ data }) => {
        commit('set_single', data.data);

        return getters.single(data.data);
      });
    },

    /* ------------------------------------------------ */

    get_orders({ commit, getters }, { id, startDate, endDate }) {
      commit('set_order_loading', { id, startDate, endDate, loading: true });
      return api
        .get(`/contracts/${id}/orders`, {
          params: {
            with: ['user.media', 'history.user.media'],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
          },
        })
        .then(({ data }) => {
          data.data.forEach((o) => {
            commit('set_order', o);
          });

          commit('set_order_loading', {
            id,
            startDate,
            endDate,
            loading: false,
          });
          return getters.orders(id, startDate, endDate);
        });
    },
    get_order({ commit, getters }, { contractId, id }) {
      return api
        .get(`/contracts/${contractId}/orders/${id}`, {
          params: {
            with: ['user.media', 'history.user.media'],
          },
        })
        .then(({ data }) => {
          commit('set_order', data.data);

          return getters.order({ id });
        });
    },
    update_order({ commit, getters }, { contractId, order }) {
      return api
        .put(`/contracts/${contractId}/orders/${order.id}`, order)
        .then(({ data }) => {
          commit('set_order', data.data);

          return getters.order(data.data);
        });
    },
    create_order({ commit, getters }, { contractId, order }) {
      return api
        .post(`/contracts/${contractId}/orders`, order)
        .then(({ data }) => {
          commit('set_order', data.data);

          return getters.order(data.data);
        });
    },
  },
};
