import { ref } from 'vue';

const _interface = ref(null);

function useToaster() {
  function setToasterInterface(value) {
    _interface.value = value;
  }

  function toaster() {
    return _interface.value;
  }

  function addToast(config) {
    _interface.value.add(config);
  }

  function removeToast(id) {
    _interface.value.remove(id);
  }

  return {
    setToasterInterface, toaster, addToast, removeToast,
  };
}

export { useToaster };
