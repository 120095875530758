import emitter from 'tiny-emitter/instance';
import debounce from 'lodash/debounce';

let once = false
function registerEmitter() {
  if (once) return;

  window.addEventListener('resize', debounce(() => {
    emitter.emit('resize');
  }, 200));

  document.addEventListener('click', () => {
    emitter.emit('click');
  });

  once = true
}

export { registerEmitter, emitter }
