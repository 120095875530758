import cookies from 'js-cookie';
import { ROOT_DOMAIN } from '../configuration/domains';

function processTempToken(store) {
    const TEMP_TOKEN_NAME = 'temp_token';

    if (!(store && typeof store.commit === 'function')) throw new Error('Please provide store');
    if (!cookies.get(TEMP_TOKEN_NAME)) return false;

    store.commit('auth/updateToken', cookies.get(TEMP_TOKEN_NAME));
    cookies.remove(TEMP_TOKEN_NAME, {
        path: '/',
        domain: `.${ROOT_DOMAIN}`,
    });

    return true;
}

export { processTempToken }