import {QueryAbstract} from './query-abstract';

class QueryServices extends QueryAbstract {
  reset() {
    this._services = [];
  }

  serialize() {
    return {
      services: this._services,
    }
  }

  unserialize(filter) {
    const { services } = filter;

    if (typeof services === 'string') this._services = [services];
    if (Array.isArray(services)) this._services = services;
  }

  set services(value) {
    this._services = value;
  }

  get services() {
    return this._services;
  }
}

export { QueryServices }