import BaseModel from '@/store/models/BaseModel';
import { parseJSON } from '@/helpers/json';

const HIDDEN = ['from', 'to'];

class HistoryLog extends BaseModel {
  constructor(props) {
    super(props, HIDDEN);
  }

  get from() {
    const from = parseJSON(super.get('from'));
    if (typeof from.order_lines === 'string') {
      from.order_lines = parseJSON(from.order_lines);
    }
    return from;
  }

  get to() {
    const to = parseJSON(super.get('to'));
    if (typeof to.order_lines === 'string') {
      to.order_lines = parseJSON(to.order_lines);
    }
    return to;
  }
}

export default HistoryLog;
