import { createStore } from 'vuex';
import { addUnauthorizedInterceptor } from '@/store/plugins/api.js';
import apiplugin from './plugins/apiplugin';
import sockets from './plugins/sockets';

import auth from './modules/auth';
import calendar from './modules/calendar';
import categories from './modules/categories';
import contracts from './modules/contracts';
import conversations from './modules/conversations';
import conversationLogs from './modules/conversation-logs';
import employees from './modules/employees';
import leadContracts from './modules/lead/contracts';
import loading from './modules/loading';
import locations from './modules/locations';
import lunch from './modules/lunch';
import media from './modules/media';
import modules from './modules/modules';
import offers from './modules/offers';
import partners from './modules/partners';
import services from './modules/services';
import settings from './modules/settings';
import split from './modules/split';
import tasks from './modules/tasks';
import team from './modules/team';
import ui from './modules/ui';
import users from './modules/users';
import wootric from './modules/wootric';
import webshopOrders from './modules/webshop/orders';

const store = createStore({
  modules: {
    auth,
    calendar,
    categories,
    contracts,
    conversations,
    conversationLogs,
    employees,
    leadContracts,
    loading,
    locations,
    lunch,
    media,
    modules,
    offers,
    partners,
    services,
    settings,
    split,
    tasks,
    team,
    ui,
    users,
    wootric,
    webshop: {
      namespaced: true,
      modules: {
        orders: webshopOrders,
      },
    },
  },
  plugins: [apiplugin, sockets],
});

// This needs to be added here as it has a dependency to the store
// that can not be directly fulfilled as direct dependency in the api as
// the store has a dependency to the api and we end up with a
// circular dependency or a hen and egg problem where the store isn't yet
// set up but the API needs it.
addUnauthorizedInterceptor(store);

export default store;
