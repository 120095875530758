import {QueryAbstract} from './query-abstract';

class QueryOgDirect extends QueryAbstract {
  reset() {
    this._og_direct = null; // Boolean
  }

  serialize() {
    return {
      og_direct: this._og_direct,
    }
  }

  unserialize(filter) {
    const { og_direct } = filter;

    if (og_direct === 'true') this.og_direct = true;
    if (og_direct === 'false') this.og_direct = false;

    if (typeof this.og_direct !== 'boolean') this.og_direct = null;
  }

  set og_direct(value) {
    this._og_direct = value;
  }

  get og_direct() {
    return this._og_direct;
  }
}

export {QueryOgDirect}