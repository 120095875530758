import {QueryAbstract} from './query-abstract';

class QueryIsVerified extends QueryAbstract {
    reset() {
        this._is_verified = null;
    }

    serialize() {
        return {
            is_verified: this._is_verified,
        }
    }

    unserialize(filter) {
        const { is_verified } = filter;

        if (typeof is_verified === 'string') {
            this.is_verified = (is_verified === 'true');
            return;
        }

        if (typeof is_verified === 'boolean') {
            this.is_verified = is_verified;
        } else {
            this.is_verified = null;
        }

    }

    set is_verified(value) {
        this._is_verified = value;
    }

    get is_verified() {
        return this._is_verified;
    }

    set isVerified(value) {
        this._is_verified = value;
    }

    get isVerified() {
        return this._is_verified;
    }
}

export { QueryIsVerified }