<template>
  <ConversationsList :ai-enabled="aiEnabled" />
</template>

<script>
import { ConversationsList } from '@officeguru/components-vue3';

export default {
  name: 'ConversationsListPage',
  components: { ConversationsList },
  computed: {
    aiEnabled() {
      return this.$store.getters['split/canAccess'](
        'customer-conversations-ai-enabled'
      );
    },
  },
};
</script>
