import BaseModel from '@/store/models/BaseModel';
import moment from 'moment-timezone';

const hidden = ['time', 'messages'];

export default class CalendarEvent extends BaseModel {
  #getters;

  constructor(props, getters) {
    super(props, hidden);
    this.getters = getters;
  }

  get label() {
    return super.get('title');
  }

  get time() {
    return super.get('time').slice(0, 5);
  }

  get read() {
    return super.get('messages', []).every((message) => message.read === true);
  }

  get isInPast() {
    const today = moment();
    return moment(super.get('date')).isBefore(today);
  }

  get hasRating() {
    return !!super.get('reviews', []).length;
  }

  get rating() {
    return this.hasRating ? super.get('reviews')[0].rating : null;
  }

  get messages() {
    return super.get('messages', []);
  }

  get partnerName() {
    return super.get('partner', {}).name;
  }

  get formattedDateString() {
    return moment.utc(super.get('date')).format('LL');
  }
}
