import BaseModel from '@/store/models/BaseModel';

const HIDDEN = ['contracts', 'services', 'offers'];

export default class Partner extends BaseModel {
  #getters;

  constructor(props, getters) {
    super(props, HIDDEN);
    this.getters = getters;
  }

  get services() {
    const serviceIds = super.get('services', []).map((s) => s.id);

    return this.getters['services/list']
      .filter((s) => serviceIds.includes(s.id))
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  get offers() {
    return this.getters['offers/madeByPartner'](super.get('id'));
  }

  get contracts() {
    return this.getters['contracts/madeByPartner'](super.get('id'));
  }

  get combined() {
    const combined = [...this.contracts];
    const tasksIds = combined.map((c) => c.task.id);
    // Add any offer to combined, that doesn't already have a contract
    this.offers.forEach((offer) => {
      if (!tasksIds.includes(offer.task.id)) {
        combined.push(offer);
      }
    });

    return combined;
  }
}
