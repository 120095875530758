import User from '@/store/models/User';
import i18n from '@/i18n';
const { t: $t } = i18n.global;
import api from '../plugins/api';
import { posthog } from '../../plugins/posthog';
import { useToaster } from '@/composables/use-toaster';

export default {
  namespaced: true,
  state: {
    contacts: null,
    customer: null,
    meId: null,
    users: [],
  },
  getters: {
    customer(state) {
      return state.customer;
    },
    contacts(state) {
      return state.contacts;
    },
    me: (state) => {
      const user = state.users.find((u) => u.id === state.meId);
      return user ? new User(user) : null;
    },
  },
  mutations: {
    set_me(state, user) {
      state.meId = user.id;
      if (user.customer) {
        state.customer = user.customer;
      }
    },

    set_customer(state, customer) {
      state.customer = customer;
    },
    set_single(state, user) {
      const oldItem = state.users.find((u) => u.id === user.id);
      if (oldItem) {
        const index = state.users.findIndex((u) => u.id === user.id);
        state.users[index] = { ...oldItem, ...user };
      } else {
        state.users.push(user);
      }
    },
    set_contacts(state, contacts) {
      state.contacts = contacts.filter(
        (c, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(c)) === i
      );
    },
  },
  actions: {
    get_me({ commit, dispatch, getters }) {
      return api
        .get('/me', {
          params: {
            with: ['customer.contact', 'customer.tasks_count'],
          },
        })
        .then(({ data }) => {
          if (!getters.me && data.data?.is_impersonating) {
            useToaster()
              .toaster()
              .add({
                type: 'info',
                text: $t(
                  'customer.officehub.impersonation_bar.you_are_now_logged_in_as',
                  { name: data.data.customer.name }
                ),
              });
          }
          commit('set_single', data.data);
          commit('set_me', data.data);
          dispatch('settings/setup', { isPublic: false }, { root: true });
          posthog.identify(data.data);
        });
    },
    async patch_lang({ dispatch }, lang) {
      if (typeof lang !== 'string') {
        return console.error(`users/patch_lang needs a lang string to work`);
      }

      await api.patch(`/me`, { lang });
      // We can not have `with`s when patching, so what we get in return
      // is missing the contacts, so we need to request
      // the resource again via GET and then we just run through the same
      // commit stuff as we do in `get_me` (with the same data and an
      // updated locale).
      return dispatch('get_me');
    },
    update({ commit }, { userdata, type }) {
      const [firstname, ...rest] = userdata.name.split(' ');
      const payload = {
        firstname,
        lastname: rest.length ? rest.join(' ') : '',
        email: userdata.email,
        phone: userdata.phone,
        gdpr: userdata.gdpr,
      };

      let endpoint;
      if (type === 'account') {
        endpoint = api.post('/account', payload);
      } else {
        endpoint = api.put(`/team/${userdata.id}`, payload);
      }

      return endpoint.then(({ data }) => {
        commit('set_single', data.data);
      });
    },
    update_password(context, { userdata, type }) {
      if (type === 'account') {
        return api.post('/account/password', userdata);
      }
      return api.post(`/team/${userdata.userId}/password`, userdata);
    },
    update_account_password(context, data) {
      return api.post('/account/password', data);
    },
    update_notifications({ commit }, { userdata, type }) {
      let endpoint;
      if (type === 'account') {
        endpoint = api.put('/account/settings', {
          settings: userdata.settings,
        });
      } else {
        endpoint = api.post(`/team/${userdata.id}/settings`, userdata);
      }

      return endpoint.then(({ data }) => {
        commit('set_single', data.data);
      });
    },
    update_payment({ commit }, settings) {
      return api.post('/account/payment', settings).then(({ data }) => {
        commit('set_customer', data.data);
      });
    },
    async delete(context, userdata) {
      await api.delete(`/team/${userdata.id}`);
    },
    get_contacts({ commit }) {
      return api
        .get(`/contacts`, {
          params: {
            with: ['media'],
          },
        })
        .then(({ data }) => {
          commit('set_contacts', data.data);
        });
    },
  },
};
