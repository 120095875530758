import {QueryAbstract} from './query-abstract';

class QueryIsHandled extends QueryAbstract {
    reset() {
        this._is_handled = null;
    }

    serialize() {
        return {
            is_handled: this._is_handled,
        }
    }

    unserialize(filter) {
        const { is_handled } = filter;

        if (typeof is_handled === 'string') {
            this.is_handled = (is_handled === 'true');
            return;
        }

        if (typeof is_handled === 'boolean') {
            this.is_handled = is_handled;
        } else {
            this.is_handled = null;
        }

    }

    set is_handled(value) {
        this._is_handled = value;
    }

    get is_handled() {
        return this._is_handled;
    }

    set isHandled(value) {
        this._is_handled = value;
    }

    get isHandled() {
        return this._is_handled;
    }
}

export { QueryIsHandled }