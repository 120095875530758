<template>
  <button
    ref="button"
    class="button"
    :class="`
    button--${sizeClass}
    button-type--${buttonType}
    `"
    :disabled="disabled || loading || success"
    type="button"
  >
    <Icon
      v-if="iconLeft"
      :name="iconLeft"
      :size="iconSize"
      style="margin-right: 5px;"
      :class="{ 'hide-text': loading || success }"
    />
    <span :class="{ 'hide-text': loading || success }"><slot /></span>
    <Icon
      v-if="iconRight"
      :name="iconRight"
      :size="iconSize"
      style="margin-left: 5px;"
      :class="{ 'hide-text': loading || success }"
    />

    <div
      v-if="loading || success"
      class="overlay-icon"
    >
      <spinner
        v-if="loading"
        small
        :light="type === 'primary'"
      />
      <icon
        v-if="success"
        name="checkmark"
        class="success-icon"
        :size="20"
      />
    </div>
  </button>
</template>

<script>

import tinycolor from 'tinycolor2';
import Icon from './Icon.vue';
import Spinner from './Spinner.vue';

const COLOR_MAP = {
  primary: '#601EFA',
  blue: '#601EFA',
  red: '#D64036',
  green: '#00875D',
  grey: '#374151',
  orange: '#B55E07',
  black: '#242545',
};

const TYPE_MAP = [
  'primary',
  'secondary',
  'outline',
  'link',
  'text',
];

export default {
  name: 'ButtonV2',
  components: {
    Icon,
    Spinner,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (value) => !!COLOR_MAP[value],
    },
    type: {
      type: String,
      default: TYPE_MAP[0],
      validator: (value) => TYPE_MAP.includes(value),
    },
    iconSize: {
      type: Number,
      default: 14,
    },
    large: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
  },
  computed: {
    trueColor() {
      return COLOR_MAP[this.color] || COLOR_MAP.primary;
    },
    buttonType() {
      return TYPE_MAP.includes(this.type)
        ? this.type
        : TYPE_MAP[0];
    },
    sizeClass() {
      if (this.large) return 'large';
      if (this.small) return 'small';
      return 'medium';
    },
  },
  mounted() {
    this.buttonFactory();
  },
  methods: {
    buttonFactory() {
      // calls the build function for a type of button
      this[`${this.buttonType}Button`]();
    },
    primaryButton() {
      this.buildButton({
        'text-color': '#fff',
        'background-color': this.trueColor,
        'border-color': this.trueColor,
        'hover-background-color': tinycolor(this.trueColor).lighten(5),
        'hover-border-color': tinycolor(this.trueColor).lighten(5),
        'focus-box-shadow': tinycolor(this.trueColor).setAlpha(0.28),
        'active-background-color': tinycolor(this.trueColor).lighten(7),
      });
    },
    secondaryButton() {
      this.buildButton({
        'text-color': this.color === 'primary' ? COLOR_MAP.black : this.trueColor,
        'border-color': '#C9CAD6',
        'background-color': 'transparent',
        'hover-background-color': 'rgba(195, 207, 250, 0.22)',
        'focus-box-shadow': tinycolor(COLOR_MAP.primary).setAlpha(0.28),
        'active-background-color': 'rgba(195, 207, 250, 0.22)',
      });
    },
    outlineButton() {
      this.buildButton({
        'text-color': this.trueColor,
        'background-color': 'transparent',
        'border-color': this.trueColor,
        'hover-background-color': 'rgba(195, 195, 199, 0.15)',
        'focus-box-shadow': tinycolor(this.trueColor).setAlpha(0.28),
        'active-background-color': 'transparent',
      });
    },
    textButton() {
      this.buildButton({
        'text-color': this.trueColor,
        'background-color': 'transparent',
        'border-color': 'transparent',
        'hover-background-color': 'rgba(175, 179, 219, 0.23)',
        'active-background-color': 'rgba(175, 179, 219, 0.20)',
        'focus-box-shadow': tinycolor(this.trueColor).setAlpha(0.28),
      });
    },
    linkButton() {
      this.buildButton({
        'text-color': this.trueColor,
        'background-color': 'transparent',
        'border-color': 'transparent',
        'focus-box-shadow': tinycolor(this.trueColor).setAlpha(0.28),
      });
    },
    buildButton(options) {
      // assign every option to a css variable prefixed with "--"
      Object.keys(options).forEach((key) => {
        this.$refs.button.style.setProperty(`--${key}`, options[key]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.success-icon {
  color: var(--text-color);
}

.button {
  @include font-body-14-semi-bold;
  display: inline-flex;
  padding: 0 24px;
  background: var(--background-color);
  color: var(--text-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  transition: all .2s, transform .1s ease;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background: var(--hover-background-color);
    border-color: var(--hover-border-color, var(--border-color, transparent))
  }

  &:focus-visible {
    box-shadow: 0px 0px 1px 2px var(--focus-box-shadow);
    outline: none;
  }

  &:active {
    // fallback to hover background color if no active background color is set
    background-color: var(--active-background-color, var(--hover-background-color));
  }

  &--small {
    height: 32px;
    padding: 0 16px;
  }

  &--medium {
    height: 40px;
    padding: 0 16px;
  }

  &--large {
    height: 48px;
    padding: 0 32px;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  &-type--primary {

    &:active {
      transform: scale(.96)
    }
  }

  &-type--link,
  &-type--text {
    padding: 8px 1px;

    &:has(.small) {
      padding: 4px 1px;
    }

    &:has(.large) {
      padding: 12px 1px;
    }
  }

  &-type--link {

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.hide-text {
  opacity: 0 !important;
}

.overlay-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
