const isJSON = (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }

  return true;
};

const parseJSON = (value) => {
  if (isJSON(value)) {
    const parsed = JSON.parse(value);

    return parseJSON(parsed);
  }

  return value;
};

export { isJSON, parseJSON };
