import BaseModel from '@/store/models/BaseModel';
import moment from 'moment-timezone';
import HistoryLog from '@/store/models/HistoryLog';

const HIDDEN = ['date', 'history'];

export default class Order extends BaseModel {
  #getters;

  constructor(props, getters) {
    super(props, HIDDEN);
    this.getters = getters;
  }

  get date() {
    return moment(super.get('date'));
  }

  get icon() {
    const map = {
      planned: 'time',
      delivered: 'star',
      cancelled: 'close',
    };

    return map[super.get('status')];
  }

  get humanReadableStatus() {
    const map = {};
    const status = super.get('status', 'planned');
    const translation = map[status];

    return translation || status.charAt(0).toUpperCase() + status.slice(1);
  }

  get history() {
    return super.get('history', []).map((history) => new HistoryLog(history));
  }

  get cancelled() {
    return super.get('status', 'planned') === 'cancelled';
  }
}
