<template>
  <ConfirmModal
    v-if="confirmCallback"
    open
    :confirmText="$t('customer.confirm')"
    :rejectText="$t('customer.cancel')"
    @accept="confirmCallback()"
    @reject="rejectCallback()"
    data-cy="confirmmodal"
  >
    {{ content.headline }}
  </ConfirmModal>
</template>

<script>
import { ConfirmModal } from '@officeguru/components-vue3';

export default {
  components: {
    ConfirmModal,
  },
  computed: {
    content() {
      return this.$store.getters['ui/confirmContent'];
    },
    confirmCallback() {
      return this.$store.getters['ui/confirmAccept'];
    },
    rejectCallback() {
      return this.$store.getters['ui/confirmReject'];
    },
  },
};
</script>

<style lang="scss" scoped></style>
