<template>
  <svg
    data-cy="cmp-icon"
    :class="rootClass"
    :width="width + 'px'"
    :height="height + 'px'"
    @click="$emit('click')"
  >
    <use :xlink:href="iconId" />
  </svg>
</template>

<script>
import '../iconsystem/inserter';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: { type: Number, default: null },
  },
  emits: ['click'],
  computed: {
    rootClass() {
      return {
        icon: true,
      };
    },
    iconId() {
      return `#${this.name}`;
    },
    width() {
      return this.size || 16;
    },
    height() {
      return this.size || 16;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  fill: currentColor;
}
</style>
