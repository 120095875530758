import LeadContract from '../../models/LeadContract';
import api from '../../plugins/api';

export default {
  namespaced: true,
  state: {
    all: [],
    declineModal: false,
    acceptModal: false,
  },
  getters: {
    single: (state, _1, _2, getters) => ({ id }) => {
      const contract = state.all.find((c) => c.id === id);
      return contract ? new LeadContract(contract, getters) : null;
    },
  },
  mutations: {
    set_single(state, contract) {
      const oldItem = state.all.find((c) => c.id === contract.id);
      if (oldItem) {
        const index = state.all.findIndex((c) => c.id === contract.id);
        state.all[index] = { ...oldItem, ...contract };
      } else {
        state.all.push(contract);
      }
    },
    set_declineModal(state, isOn) {
      state.declineModal = isOn;
    },
    set_acceptModal(state, isOn) {
      state.acceptModal = isOn;
    },
  },
  actions: {
    get_single({ commit }, { id }) {
      return api
        .get(`/public/contracts/${id}`)
        .then(({ data }) => {
          commit('set_single', data.data);
        })
        .catch(() => {
          window.location = '/';
        });
    },

    accept({ commit, dispatch }, { id, ...userData }) {
      return api
        .post(`public/contracts/${id}/approve`, userData)
        .then((data) => {
          commit('auth/updateToken', data.data.data.token, { root: true });
          dispatch('loading/startInitialLoad', null, { root: true });
        });
    },
    decline({ commit }, { id, ...payload }) {
      return api
        .post(`public/contracts/${id}/decline`, payload)
        .then((data) => {
          commit('set_single', data.data.data);
        });
    },
  },
};
