import {QueryAbstract} from './query-abstract';

class QueryContactPersons extends QueryAbstract {
    reset() {
        this._contact_persons = []; // Boolean
    }

    serialize() {
        return {
            contact_persons: this._contact_persons,
        }
    }

    unserialize(filter) {
        const { contact_persons } = filter;

        if (typeof contact_persons === 'string') this._contact_persons = [contact_persons];
        if (Array.isArray(contact_persons)) this._contact_persons = contact_persons;
    }

    set contactPersons(value) {
        this._contact_persons = value;
    }

    get contactPersons() {
        return this._contact_persons;
    }
}

export { QueryContactPersons }