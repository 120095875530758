<template>
  <Async
    :key="id"
    data-cy="view-conversations-single"
    :needs="{}"
    @loaded="scrollToBottom"
  >
    <template #default>
      <div
        v-if="conversation"
        class="conversation"
      >
        <div
          class="header"
          data-cy="view-conversations-header"
        >
          <div class="header__info">
            <ServiceIcon
              v-if="!conversation.is_support"
              :service-name="taskService.name"
              :service-plain-text-id="taskService.plain_text_id"
              :is-verified="isInternalService || null"
              :is-direct="isDirect"
              icon-size="large"
              class="service-icon"
            />
            <ConversationsOgIcon
              v-if="conversation.is_support"
              class="service-icon"
            />
            <div class="header__title">
              <h3 class="participant-name">
                <router-link
                  v-if="isPartner"
                  :to="partnerNameToConf"
                >
                  {{ participantName }}
                </router-link>
                <template v-else>
                  {{ participantName }}
                </template>
                <verified-badge
                  v-if="isVerifiedPartner"
                  style="margin-left: 4px"
                />
              </h3>
              <InfoTag
                v-if="conversation.is_support"
                small
                color="grey"
                class="task-badge"
              >
                {{ taskName }}
              </InfoTag>
              <InfoTag
                v-for="service in services"
                v-else
                :key="service"
                small
                color="grey"
                class="task-badge"
              >
                {{ service }}
              </InfoTag>
            </div>
          </div>

          <template v-if="!conversation.is_support && !isPublic">
            <template v-if="hasTask">
              <router-link
                v-if="!taskDeleted"
                :to="{ name: 'Task', params: { id: conversation.task.id } }"
                style="text-decoration: none"
              >
                <ButtonV2 type="link">
                  {{ $t("shared.conversations.go_to_task") }}
                </ButtonV2>
              </router-link>
              <p
                v-else-if="conversation"
                class="task-deleted"
              >
                {{ $t("shared.conversations.task_deleted") }}
              </p>
            </template>
            <template v-else>
              <!-- eslint-disable vue/no-v-html -->
              <p
                class="task-none"
                v-html="taskNoneTranslation"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>
          </template>
        </div>

        <ChatConversation
          :conversation="conversation"
          :can-chat="canChat"
          :token="token"
          :is-public="!!token"
          :with-list="withList"
          :use-handling="useHandling"
          :ai-enabled="aiEnabled"
        />
      </div>
    </template>
  </Async>
</template>

<script>
import {
  isVerifiedPartner,
  participantName,
  taskName,
} from '@officeguru/webapp-shared/src/helpers/conversation';
import { APP_NAMES } from '@officeguru/webapp-shared/src/configuration/app-names';
import { uniq, upperFirst } from 'lodash';
import ButtonV2 from '../../../components/ButtonV2.vue';
import ChatConversation from '../../../components/chat-conversation/chat-conversation.vue';
import ServiceIcon from '../../../components/service-icon.vue';
import ConversationsOgIcon from '../components/conversations-og-icon.vue';
import InfoTag from '../../../components/InfoTag.vue';
import VerifiedBadge from '../../../components/verified-badge.vue';
import Async from '../../../components/async.vue';

export default {
  name: 'ConversationsSinglePage',
  components: {
    InfoTag,
    ConversationsOgIcon,
    ServiceIcon,
    ButtonV2,
    ChatConversation,
    VerifiedBadge,
    Async,
  },
  props: {
    useHandling: {
      type: Boolean,
      default: false,
    },
    aiEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    token() {
      return this.$route.query.token || null;
    },
    isPublic() {
      return this.$route.meta.public || false;
    },
    conversation() {
      return this.$store.getters['conversations/single']({ id: this.id });
    },
    isVerifiedPartner() {
      return isVerifiedPartner(this.conversation, this.$appName);
    },
    services() {
      if (this.conversation.task?.service?.name) return [this.conversation.task.service.name];
      return uniq((this.conversation.services || []).map((s) => upperFirst(s)));
    },
    isPartner() {
      return !!(
        this.$appName === APP_NAMES.CUSTOMER
        && this.conversation.partner?.name
        && this.conversation.partner?.id
      );
    },
    partnerNameToConf() {
      if (!this.isPartner) return {};
      return { name: 'Partner Relationship', params: { id: this.conversation.partner.id } };
    },
    canChat() {
      if (this.id === 'support') {
        return true;
      }

      // @TODO: Remove `can_chat_contract` once the transition phase is gone
      // and we start only using can_chat
      return this.conversation.can_chat_contract || this.conversation.can_chat || false;
    },
    participantName() {
      return participantName(this.conversation, this.$appName, this.$t.bind(this));
    },
    taskName() {
      return taskName(this.conversation, this.$t.bind(this));
    },
    taskDeleted() {
      // eslint-disable-next-line camelcase
      return !!this.conversation.task?.deleted_at || false;
    },
    taskService() {
      return this.conversation.task?.service || {};
    },
    isInternalService() {
      return !!(this.taskService.name && this.taskService.plain_text_id);
    },
    isDirect() {
      return !!this.conversation.task?.og_direct;
    },
    hasTask() {
      return !!this.conversation.task?.id;
    },
    withList() {
      return this.$route.meta.withList || false;
    },
    taskNoneTranslation() {
      return this.$appName === APP_NAMES.CUSTOMER
        ? this.$t('shared.conversations.you_added_this_partner')
        : this.$t('shared.conversations.task_is_not_on_marketplace');
    },
  },
  watch: {
    id: {
      handler() {
        this.scrollToBottom();
        this.markConversationRead();
      },
      immediate: true,
    },
  },
  methods: {
    markConversationRead() {
      // TODO: Make sure external partner conversations can be marked read,
      // we currently get a 500 w. `BadMethodCallException`
      if (this.conversation.partner && !this.conversation.partner.is_verified) {
        return;
      }

      this.$store.dispatch('conversations/read', {
        id: this.conversation.id, // the encrypted conversation id, also for the support conv
        isSupport: this.conversation.is_support, // indicating that it's the support conversation
        isPublic: this.isPublic,
        token: this.token,
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        if (!this.$refs.scrollArea) return;

        this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight;
      });
    },
    upperFirst,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/main";

.task-deleted {
  @include font-body-14-medium;
  color: $color-grey-tinted-500;
}

.task-none {
  @include font-caption-normal;
  color: $color-grey-tinted-500;
}

.conversation {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-radius: 12px;
  overflow: hidden;

  .is-impersonating & {
    min-height: calc(100vh - 40px);
  }
}

.header {
  flex-grow: 0;
  flex-shrink: 0;
  height: 93px;
  padding: 0 24px;
  border-bottom: 1px solid $color-grey-tinted-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__info {
  display: flex;
  flex: 1 1 auto;
}

.participant-name,
.participant-name a {
  @include header-3-new-semi-bold;
  color: $color-grey-tinted-900;
  text-decoration: none;
}

.task-badge {
  margin-top: 4px;
  margin-right: 8px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
