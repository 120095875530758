import Offer from '@/store/models/Offer';
import i18n from '@/i18n';
const { t } = i18n.global;
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    all: [],
    declineModal: false,
  },
  getters: {
    single: (state) => ({ id }) => {
      const item = state.all.find((i) => i.id === id);

      return item ? new Offer(item) : null;
    },
    list(state) {
      return state.all.map((i) => new Offer(i));
    },
    madeByPartner: (state) => (partnerId) => {
      return state.all
        .filter((i) => i.partner_id === partnerId)
        .map((i) => new Offer(i));
    },
  },
  mutations: {
    set_single(state, item) {
      const oldItem = state.all.find((i) => i.id === item.id);
      if (oldItem) {
        const index = state.all.findIndex((i) => i.id === item.id);
        state.all[index] = { ...oldItem, ...item };
      } else {
        state.all.push(item);
      }
    },
    set_declineModal(state, isOn) {
      state.declineModal = isOn;
    },
  },
  actions: {
    accept({ dispatch, commit }, { task_id: taskId, id: offerId }) {
      return dispatch(
        'ui/confirm',
        {
          headline: t('customer.contracts.sure_accept_offer'),
        },
        { root: true }
      ).then(() =>
        api
          .post(`tasks/${taskId}/offers/${offerId}/approve`)
          .then(({ data }) => {
            commit('tasks/set_offer', data.data, { root: true });
          })
      );
    },
    decline({ commit }, offer) {
      return api
        .post(`tasks/${offer.task_id}/offers/${offer.id}/decline`, offer)
        .then(({ data }) => {
          commit('tasks/set_offer', data.data, { root: true });
        });
    },
    dispute({ dispatch, commit }, { task_id: taskId, id: offerId }) {
      return dispatch(
        'ui/confirm',
        {
          headline: 'Vil du lave en reklamation?',
        },
        { root: true }
      ).then(() =>
        api
          .post(`tasks/${taskId}/offers/${offerId}/dispute`)
          .then(({ data }) => {
            commit('tasks/set_offer', data.data, { root: true });
          })
      );
    },
  },
};
