import {
  getImpersonationModeToken,
  getNonImpersonationModeToken,
} from '@/api/officehub';
import { nextTick } from 'vue';
import store from '@/store';

function useOfficehub() {
  async function enterImpersonationMode({
    impersonatorId,
    location = '/',
  } = {}) {
    const { data } = await getImpersonationModeToken(impersonatorId);
    store.commit('auth/updateToken', data.data.token);

    await nextTick();

    window.location = location;
  }

  async function leaveImpersonationMode() {
    const { data } = await getNonImpersonationModeToken();
    store.commit('auth/updateToken', data.data.token);

    await nextTick();

    window.location = '/officehub/accounts';
  }

  return {
    enterImpersonationMode,
    leaveImpersonationMode,
  };
}

export { useOfficehub };
