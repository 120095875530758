import BaseModel from '@/store/models/BaseModel';
import { differenceInDays } from 'date-fns';

const HIDDEN = ['created_at', 'delivery_date'];
export default class Order extends BaseModel {
  constructor(props, getters) {
    super(props, HIDDEN);
    this.getters = getters;
  }

  get title() {
    return `${this.created_at} - ${this.partner.name}`;
  }

  get created_at() {
    return new Date(super.get('created_at')).toLocaleString(
      this.getters['settings/locale'],
      {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
    );
  }

  get created_at_short() {
    return new Date(super.get('created_at')).toLocaleString(
      this.getters['settings/locale'],
      {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }
    );
  }

  get delivery_date() {
    const date = super.get('delivery_date');
    if (!date) return null;

    return new Date(date).toLocaleString(this.getters['settings/locale'], {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  get delivery_date_short() {
    const date = super.get('delivery_date');
    if (!date) return null;

    return new Date(date).toLocaleString(this.getters['settings/locale'], {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  get recently_created() {
    return differenceInDays(new Date(), new Date(super.get('created_at'))) < 1;
  }
}
